import instance from 'api'
// import { errorValidator } from 'util/errorValidator'
// import { errorValidator } from 'util/errorValidator'

const authService = {}

authService.getUserProfile = async function () {
  try {
    const { data } = await instance.get('/api/v1/customers')
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

authService.createUser = async function (data) {
  try {
    const res = await instance.post('/api/v1/customers/register', data)
    return res
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

authService.updateUser = async function (data) {
  try {
    const res = await instance.put('/api/v1/customers', data)
    return res
  } catch (err) {
    // //console.log(err.response.data.response, 'show-err')
  }
}

// Address
authService.createUserAddress = async function (data) {
  try {
    const res = await instance.post('/api/v1/customers/address', data)
    return res
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

authService.updateUserAddress = async function (id, data) {
  try {
    const res = await instance.put(`/api/v1/customers/address/${id}`, data)
    return res
  } catch (err) {
    //console.log(err.response, 'show-err-up')
    // errorValidator(err.response.data)

    // //console.log(err, 'show-err')
  }
}

authService.deleteUserAddress = async function (id, data) {
  try {
    const res = await instance.delete(`/api/v1/customers/address/${id}`, data)
    return res
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

// Prescription
authService.getUserPrescriptions = async function () {
  try {
    const {
      data: { data },
    } = await instance.get('/api/v1/customers/prescriptions')
    return data.prescriptions
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

authService.updateUserPrescriptions = async function (data) {
  //console.log('prescriptiommm', data)
  try {
    const res = await instance.put(`/api/v1/customers/prescriptions`, data)
    return res
  } catch (err) {
    //console.log(err.response, 'show-err-up')
    // errorValidator(err.response.data)

    // //console.log(err, 'show-err')
  }
}

authService.updateUserOrderPrescriptions = async function (id, data) {
  try {
    const res = await instance.post(
      `/api/v1/order/customer/prescriptions/${id}`,
      data
    )
    return res
  } catch (err) {
    //console.log(err.response, 'show-err-up')
    // errorValidator(err.response.data)

    // //console.log(err, 'show-err')
  }
}

export default authService
