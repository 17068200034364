import instance from 'api'
const manufacturerService = {}

manufacturerService.getManufactures = async function () {
  try {
    const {
      data: { data },
    } = await instance.get('/api/v1/manufacturers/public')
    const resData = data.filter((cur) => cur.status === 'Active')
    return resData
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

manufacturerService.getManufactureById = async function (id) {
  try {
    const {
      data: { data },
    } = await instance.get(`/api/v1/manufacturers/${id}/public`)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

export default manufacturerService
