/* eslint-disable react/prop-types */
// Private Route desicion Render
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Navigate } from 'react-router-dom';
// import Preloader from '../components/common/Preloader'

const PrivateRoute = ({ component: Component, ...props }) => {
  // Later Change to real redux auth state
  const { authorized, userLoading } = useSelector((state) => state.auth);
  // const userLoading = false
  // const  = true

  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    if (!userLoading) {
      if (authorized) {
        setLoading(false);
        setAuth(true);
      } else {
        setLoading(false);
      }
    }
  }, [authorized, userLoading]);

  // const authenticated = authentication?.currentUser?.uuid ? true : false
  return (
    <>
      {loading ? (
        <div></div>
      ) : !loading && auth ? (
        <Component {...props} />
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default PrivateRoute;
