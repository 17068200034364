/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addToCartSlice } from 'redux/slices/cartSlice';
// import OutsideClickHandler from 'react-outside-click-handler'

const SearchSuggestion = ({
  searchSuggestions,
  onSelect,
  searchTerm = '',
  setIsSuggestionShown,
  isSuggestionShown
}) => {
  const { authorized } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // const availableSearchsuggestions = searchSuggestions?.filter((cur) =>
  //   cur.text.toLowerCase().includes(searchTerm.toLowerCase())
  // )

  const addToCart = async (product) => {
    if (authorized) {
      // const resp = await cartService.addCartItem({ productId: product.id })

      dispatch(addToCartSlice(product.productId));
      // if (resp) {
      //   toast.success(`Added ${product.name} To Cart`)
      // }
      // try {
      //   const sendingData = {
      //     productId: product._id,
      //     quantity: product.minOrderQty,
      //   }
      //   const { data } = await addToCartApi(sendingData)
      //   data && toast.success('add To Cart')
      // } catch (err) {
      //   toast.error(err.response.data.error)
      // }
    } else {
      toast.warning('You have to login before adding to cart');
      navigate('/Login');
    }
    // const getCartItem = window.localStorage.getItem('cartItems')
    // if (getCartItem) {
    //   const parsedItems = JSON.parse(getCartItem)
    //   const storeItem = [...parsedItems, product]

    //   window.localStorage.setItem('cartItems', JSON.stringify(storeItem))
    //   toast.success('add To Cart')
    // } else {
    //   window.localStorage.setItem('cartItems', JSON.stringify([product]))
    //   toast.success('add To Cart')
    // }
  };

  console.log('search suggestions', searchSuggestions)

  return (
    <>
      {searchTerm?.trim().length > 0 &&
        isSuggestionShown &&
        searchSuggestions?.length > 0 && (
          <div className="search-suggestions">
            {searchSuggestions?.map((cur, i) => (
              <div key={cur.text + i} style={{ position: 'relative' }}>
                <div
                  className="search-item d-flex align-items-center p-2"
                  style={{ width: '92%', overflow: 'hidden' }}
                  onClick={() =>
                    onSelect(
                      cur?.productTemplateSlug,
                      cur?.productId,
                      cur?.productType,
                      cur?.category
                    )
                  }
                >
                  {cur?.image && (
                    <img src={cur.image} alt="product" height={32} width={32} />
                  )}

                  <div className="ml-10">
                    <p>{cur.text}</p>
                    {cur.category}
                  </div>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    height: '100%',

                    zIndex: 1000
                  }}
                >
                  <button
                    type="button"
                    className="button button-add-to-cart search-cart px-3 ml-0 mb-0"
                    onClick={(e) => addToCart(cur)}
                  >
                    <i className="fi-rs-shopping-cart"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      {/* </OutsideClickHandler> */}
    </>
  );
};

export default SearchSuggestion;
