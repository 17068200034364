const getFBError = (err) => {
  switch (err) {
    case 'auth/admin-restricted-operation':
      return 'You do not have permission to perform this operation.'
    case 'auth/argument-error':
      return 'An invalid argument was provided.'
    case 'auth/app-not-authorized':
      return 'This app is not authorized to use Firebase Authentication.'
    case 'auth/app-not-installed':
      return 'The requested app is not installed.'
    case 'auth/captcha-check-failed':
      return 'The reCAPTCHA check failed.'
    case 'auth/code-expired':
      return 'The code has expired.'
    case 'auth/cordova-not-ready':
      return 'Cordova is not ready.'
    case 'auth/cors-unsupported':
      return 'This browser is not supported.'
    case 'auth/credential-already-in-use':
      return 'This credential is already associated with a different user account.'
    case 'auth/custom-token-mismatch':
      return 'The custom token corresponds to a different audience.'
    case 'auth/requires-recent-login':
      return 'This operation is sensitive and requires recent authentication. Log in again before retrying this request.'
    case 'auth/dependent-sdk-initialized-before-auth':
      return 'The SDK for a given provider has not been initialized.'
    case 'auth/dynamic-link-not-activated':
      return 'The provided dynamic link has not been activated.'
    case 'auth/email-change-needs-verification':
      return 'The email address is already in use by another account. Additionally, this email address must be verified before it can be used.'
    case 'auth/email-already-in-use':
      return 'The email address is already in use by another account.'
    case 'auth/emulator-config-failed':
      return 'The emulator is not configured correctly.'
    case 'auth/expired-action-code':
      return 'The action code has expired.'
    case 'auth/cancelled-popup-request':
      return 'The operation has been cancelled by the user.'
    case 'auth/internal-error':
      return 'An internal error has occurred.'
    case 'auth/invalid-api-key':
      return 'The API key is invalid.'
    case 'auth/invalid-app-credential':
      return 'The application credential is invalid.'
    case 'auth/invalid-app-id':
      return 'The application ID is invalid.'
    case 'auth/invalid-user-token':
      return "The user's credential is no longer valid. The user must sign in again."
    case 'auth/invalid-auth-event':
      return 'The user has attempted to log in with a wrong password too many times, thus locking them out for a given amount of time.'
    case 'auth/invalid-cert-hash':
      return 'The certificate hash is invalid.'
    case 'auth/invalid-verification-code':
      return 'The verification code is invalid.'
    case 'auth/invalid-continue-uri':
      return 'The continue URL provided is invalid.'
    case 'auth/invalid-cordova-configuration':
      return 'The provided CORDOVA configuration is invalid.'
    case 'auth/invalid-custom-token':
      return 'The custom token format is invalid.'
    case 'auth/invalid-dynamic-link-domain':
      return 'The provided dynamic link domain is not configured or authorized for the current project.'
    case 'auth/invalid-email':
      return 'The email address is invalid.'
    case 'auth/invalid-emulator-scheme':
      return 'The provided emulator launch URL is invalid.'
    case 'auth/already-initialized':
      return 'The provided credential is invalid.'
    case 'auth/invalid-message-payload':
      return 'The provided message payload is invalid.'
    case 'auth/invalid-multi-factor-session':
      return 'The provided session for a multi-factor login is invalid.'
    case 'auth/invalid-oauth-client-id':
      return 'The provided OAuth client ID is invalid.'
    case 'auth/invalid-oauth-provider':
      return 'The provided OAuth provider is invalid.'
    case 'auth/invalid-action-code':
      return 'The action code is invalid.'
    case 'auth/unauthorized-domain':
      return 'The domain of the continue URL is not whitelisted. Please whitelist the domain in the Firebase console.'
    case 'auth/wrong-password':
      return 'The password is invalid or the user does not have a password.'
    case 'auth/invalid-persistence-type':
      return 'The specified persistence type is invalid.'
    case 'auth/invalid-phone-number':
      return 'The phone number has an invalid format.'
    case 'auth/invalid-provider-id':
      return 'The specified provider ID is invalid.'
    case 'auth/invalid-recipient-email':
      return 'The recipient email address is invalid.'
    case 'auth/invalid-sender':
      return 'The email address of the sender is invalid.'
    case 'auth/invalid-verification-id':
      return 'The verification ID is invalid.'
    case 'auth/invalid-tenant-id':
      return 'The tenant ID is invalid.'
    case 'auth/multi-factor-info-not-found':
      return 'The multi-factor information was not found.'
    case 'auth/multi-factor-auth-required':
      return 'Sign-in failed because the user has not provided all the required verification factors.'
    case 'auth/missing-android-pkg-name':
      return 'An Android Package Name must be provided if the Android App is required.'
    case 'auth/missing-app-credential':
      return 'The phone verification request is missing an application credential.'
    case 'auth/auth-domain-config-required':
      return 'The authDomain configuration is not provided.'
    case 'auth/missing-verification-code':
      return 'The phone verification code is missing.'
    case 'auth/missing-continue-uri':
      return 'The continue URL is missing.'
    case 'auth/missing-iframe-start':
      return 'The iframe start URL is missing.'
    case 'auth/missing-ios-bundle-id':
      return 'An iOS Bundle ID must be provided if the iOS App is required.'
    case 'auth/missing-or-invalid-nonce':
      return 'The nonce is missing or invalid.'
    case 'auth/missing-multi-factor-info':
      return 'The multi-factor information is missing.'
    case 'auth/missing-multi-factor-session':
      return 'The multi-factor session ID is missing.'
    case 'auth/missing-phone-number':
      return 'The phone number is missing.'
    case 'auth/missing-verification-id':
      return 'The verification ID is missing.'
    case 'auth/app-deleted':
      return 'The application has been deleted.'
    case 'auth/account-exists-with-different-credential':
      return 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'
    case 'auth/network-request-failed':
      return 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.'
    case 'auth/null-user':
      return 'A null user object was provided as the user parameter.'
    case 'auth/no-auth-event':
      return 'An internal error has occurred.'
    case 'auth/no-such-provider':
      return 'The specified provider does not exist.'
    case 'auth/operation-not-allowed':
      return 'The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.'
    case 'auth/operation-not-supported-in-this-environment':
      return 'This operation is not supported in the environment this application is running on. "location.protocol" must be "https:" if running on HTTPS, and "location.protocol" must be "http:" if running on HTTP.'
    case 'auth/popup-blocked':
      return 'The popup was blocked by the browser.'
    case 'auth/popup-closed-by-user':
      return 'The popup was closed by the user.'
    case 'auth/provider-already-linked':
      return 'The provider has already been linked.'
    case 'auth/quota-exceeded':
      return 'The quota for this operation has been exceeded.'
    case 'auth/redirect-cancelled-by-user':
      return 'The redirect operation has been cancelled by the user.'
    case 'auth/redirect-operation-pending':
      return 'A redirect operation is already in progress.'
    case 'auth/rejected-credential':
      return 'The request contains malformed or invalid data.'
    case 'auth/second-factor-already-in-use':
      return 'The second factor has already been added.'
    case 'auth/maximum-second-factor-count-exceeded':
      return 'The maximum number of second factors has been reached.'
    case 'auth/tenant-id-mismatch':
      return "The provided tenant ID does not match the Auth instance's tenant ID."
    case 'auth/timeout':
      return 'The operation has timed out.'
    case 'auth/user-token-expired':
      return "The user's credential has expired."
    case 'auth/too-many-requests':
      return 'Too many requests have been made using this credential.'
    case 'auth/unauthorized-continue-uri':
      return 'The continue URL is not authorized.'
    case 'auth/unsupported-first-factor':
      return 'The first factor is not supported.'
    case 'auth/unsupported-persistence-type':
      return 'The specified persistence type is not supported.'
    case 'auth/unsupported-tenant-operation':
      return 'The tenant operation is not supported.'
    case 'auth/unverified-email':
      return 'The email address is unverified.'
    case 'auth/user-cancelled':
      return 'The user has cancelled the operation.'
    case 'auth/user-not-found':
      return 'The user does not exist.'
    case 'auth/user-disabled':
      return 'The user has been disabled.'
    case 'auth/user-mismatch':
      return "The user's credential is not valid for the given provider."
    case 'auth/user-signed-out':
      return 'The user is signed out.'
    case 'auth/weak-password':
      return 'The password is too weak.'
    case 'auth/web-storage-unsupported':
      return 'The Web Storage feature is not supported by the browser.'
    default:
      return err
  }
}

export default getFBError
