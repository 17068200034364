// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit';
import homeWidgetService from 'service/homeWidget';
// import cartService from 'service/cart'

// import { notification } from 'antd';
// import history from 'utils/history';
// TODO -> Handle api errors

export const HomeWidget = createSlice({
  name: 'homeWidget',
  initialState: {
    homeWidgets: [],
    homeWidget: {},
    loading: false
  },
  reducers: {
    setHomeWidgets: (state, action) => {
      state.homeWidgets = action.payload;
    },
    setHomeWidget: (state, action) => {
      state.homeWidget = action.payload;
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const { setHomeWidgets, setHomeWidget, setLoading } = HomeWidget.actions;

export const getHomeWidget = () => async (dispatch) => {
  dispatch(setLoading(true));
  const data = await homeWidgetService.getHomeWidget();

  if (data) {
    // data.forEach((widget) => {
    //   if (widget.listingType === 'ProductTemplates') {
    //     widget.listingItems.map((item) => {
    //       if (item?.products?.length > 0) {
    //         item?.products?.forEach((prod) => {
    //           prod.default && defaultProducts.push(prod)
    //         })
    //       } else {
    //         defaultProducts.push(item)
    //       }
    //     })
    //     return { ...widget, defaultProducts }
    //   }
    // })

    // We are setting the default products to the home widget
    for (let i = 0; i < data.length; i++) {
      if (data[i].listingType === 'ProductTemplates') {
        const defaultProducts = [];
        data[i].listingItems.map((item) => {
          if (item?.products?.length > 0) {
            item?.products?.forEach((prod) => {
              prod.default &&
                defaultProducts.push({
                  ...prod,
                  productTemplateSlug: item?.slug,
                  medicinePackaging: item?.medicinePackaging,
                  images: prod?.images?.length > 0 ? prod?.images : item?.images
                });
            });
          } else {
            defaultProducts.push(item);
          }
        });
        data[i].defaultProducts = defaultProducts;
      }
    }

    dispatch(setHomeWidgets(data));
  }
  dispatch(setLoading(false));
  //   if (data) {
  //     dispatch(setCart(data))
  //     let sum = 0
  //     data.map((item) => {
  //       //console.log(item, 'carttt')
  //       sum += item.quantity * item.product.price
  //     })
  //     dispatch(setCartTotal(sum))
  //   }
  //   dispatch(setLoading(false))
};

export const getHomeWidgetById = (id) => async (dispatch) => {
  const { data } = await homeWidgetService.getHomeWidgetById(id);

  if (data) {
    dispatch(setHomeWidget(data));
  }
};

export default HomeWidget.reducer;
