// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit';
import settingService from 'service/settings';

// import { notification } from 'antd';
// import history from 'utils/history';
// TODO -> Handle api errors

export const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    settings: {},
    loading: false,
    isFetched: false
  },
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsFetched: (state, action) => {
      state.isFetched = action.payload;
    }
  }
});

export const { setSettings, setLoading, setIsFetched } = settingSlice.actions;

export const getSettings = () => async (dispatch) => {
  dispatch(setLoading(true));
  const data = await settingService.getSettings();
  if (data) {
    dispatch(setSettings(data));
    dispatch(setIsFetched(true));
  }
  dispatch(setLoading(false));
};

export default settingSlice.reducer;
