import { notification } from 'antd';
import instance from 'api';
import { toast } from 'react-toastify';
const orderService = {};

orderService.getOrders = async function (query = '') {
  try {
    const {
      data: { data }
    } = await instance.get(
      `/api/v1/order/customer/view_all${query && `?${query}`}`
    );
    // const resData = data.filter((cur) => cur.status === 'Active')
    return data;
  } catch (err) {
    //console.log(err.response.data, 'show-err');
  }
};

orderService.getOrderById = async function (id) {
  try {
    const {
      data: { data }
    } = await instance.get(`/api/v1/order/customer/${id}`);
    // const resData = data.filter((cur) => cur.status === 'Active')
    return data;
  } catch (err) {
    //console.log(err.response.data, 'show-err');
  }
};

orderService.createOrder = async function (data) {
  try {
    const res = await instance.post(`/api/v1/order/customer/request`, data);
    return res;
  } catch (err) {
    const parsedErr = JSON.parse(err.response.data.detail);
    if (parsedErr) {
      parsedErr.forEach((cur) => {
        toast.error(cur.type);
        toast.error(cur.item.name);
        // notification.error({
        //   message: cur.type,
        //   description: cur.item.name,
        // })
      });
    }
  }
};

orderService.cancelOrder = async function (id) {
  try {
    const res = await instance.delete(`/api/v1/order/customer/${id}`);
    return res;
  } catch (err) {
    toast.error(err.response.data.detail);
  }
};
orderService.cancelOrderItem = async function (id, data) {
  try {
    const res = await instance.post(`/api/v1/order/customer/${id}`, data);
    return res;
  } catch (err) {
    toast.error(err.response.data.detail);
  }
};

orderService.reOrderItem = async function (id) {
  try {
    const res = await instance.post(`/api/v1/order/customer/reorder/${id}`);
    return res;
  } catch (err) {
    toast.error(err.response.data.detail);
  }
};

orderService.returnOrderItem = async function (id, data) {
  try {
    const res = await instance.put(`/api/v1/order/customer/${id}`, data);
    return res;
  } catch (err) {
    toast.error(err.response.data.detail);
  }
};

orderService.getBankAccounts = async function () {
  try {
    const res = await instance.get(`/api/v1/payment/user/getFundAccounts`);
    return res;
  } catch (err) {
    toast.error(err.response.data.detail);
  }
};

orderService.addBankAccount = async function (data) {
  //console.log("entered onSubmitBankHandler api")
  try {
    const res = await instance.post(`/api/v1/payment/addFundAccountVendor`, data);
    return res;
  } catch (err) {
    toast.error(err.response.data.detail);
  }
};

orderService.orderPaymentMethod = async function (data) {
  try {
    const res = await instance.post(`/api/v1/payment/paymentMethod`, data);
    // alert(JSON.stringify(res.data))
    return res.data;
  } catch (err) {
    const parsedErr = JSON.parse(err.response.data.detail);

    if (parsedErr) {
      parsedErr.forEach((cur) => {
        notification.error({
          message: cur.type,
          description: cur.item.name
        });
      });
    }
  }
};

orderService.applyCoupon = async function (code) {
  try {
    const {
      data: { data }
    } = await instance.get(`/api/v1/coupon/applycoupon/${code}`);
    // const resData = data.filter((cur) => cur.status === 'Active')
    return data;
  } catch (err) {
    //console.log(err.response.data, 'show-err');
  }
};

orderService.applicableCoupons = async function () {
  try {
    const {
      data: { data }
    } = await instance.get(`/api/v1/coupon/applicableCoupons`);

    return data;
  } catch (err) {
    //console.log(err.response.data, 'show-err');
  }
};

orderService.checkIfDeliverable = async function (productId, zipCode) {
  try {
    const { data } = await instance.post(
      `/api/v1/order/customer/checkIfDeliverable`,
      {
        productId,
        uniqueId: zipCode
      }
    );
    return data;
  } catch (err) { }
};

orderService.checkIfDeliverableByAddress = async function (sendingData) {
  try {
    const { data } = await instance.post(
      `/api/v1/order/customer/checkIfDeliverable/byaddress`,
      sendingData
    );
    return data;
  } catch (err) { }
};

orderService.trackOrder = async function (shipmentId) {
  try {
    const { data } = await instance.get(`/api/v1/shipment/track/${shipmentId}`);

    return data.tracking_data;
  } catch (err) {
    //console.log(err.response.data, 'show-err');
  }
};

export default orderService;
