import instance from 'api'

const wishlistService = {}

wishlistService.getWishlists = async function () {
  try {
    const {
      data: {
        data: { items },
      },
    } = await instance.get('/api/v1/wishlist')
    return items
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

wishlistService.addWishlistItem = async function (sendingData) {
  try {
    const { data } = await instance.post('/api/v1/wishlist', sendingData)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

wishlistService.deleteWishlistItem = async function (productId) {
  try {
    const { data } = await instance.delete(`/api/v1/wishlist/${productId}`)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

export default wishlistService
