// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit';
import categoryService from 'service/category';

// import { notification } from 'antd';
// import history from 'utils/history';
// TODO -> Handle api errors

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    categoriesHeader: [],
    categoriesFooter: [],
    category: {},
    loading: false,
    isFetchedFooter: false,
  },
  reducers: {
    setCategoriesHeader: (state, action) => {
      state.categoriesHeader = action.payload;
    },
    setCategoriesFooter: (state, action) => {
      state.categoriesFooter = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsFetchedFooter: (state, action) => {
      state.isFetchedFooter = action.payload;
    }
  }
});

export const {
  setCategoriesHeader,
  setCategoriesFooter,
  setCategories,
  setCategory,
  setLoading,
  setIsFetchedFooter
} = categorySlice.actions;

export const getCategories = (filter, section) => async (dispatch) => {
  dispatch(setLoading(true));
  const data = await categoryService.getCategories(filter);
  if (data) {
    if (section === 'footer') {
      dispatch(setCategoriesFooter(data));
      dispatch(setIsFetchedFooter(true));
    } else if (section === 'header') {
      dispatch(setCategoriesHeader(data));
    } else {
      dispatch(setCategory(data));
    }
  }
  dispatch(setLoading(false));
};

export const getCategory = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  const data = await categoryService.getCategoryById(id);
  if (data) {
    dispatch(setCategory(data));
  }
  dispatch(setLoading(false));
};

export default categorySlice.reducer;
