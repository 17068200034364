// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit'
import productService from 'service/product'

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    product: {},
    loading: false,
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setProduct: (state, action) => {
      state.product = action.payload
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setProducts, setProduct, setLoading } = productSlice.actions

export const getProducts =
  (queryString = '') =>
  async (dispatch) => {
    dispatch(setLoading(true))
    const data = await productService.getProducts(queryString)
    if (data) {
      dispatch(setProducts(data))
    }
    dispatch(setLoading(false))
  }

export const getProduct = (id) => async (dispatch) => {
  dispatch(setLoading(true))
  const data = await productService.getProductById(id)
  if (data) {
    dispatch(setProduct(data))
  }
  dispatch(setLoading(false))
}

export default productSlice.reducer
