// // Add Up Redux Slices Here
// // create redux slice function as reducer

// // Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import wishlistService from 'service/wishlist';

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {
    wishlist: [],
    totalWishlistItems: 0,
    loading: false,
    isFetched: false
  },
  reducers: {
    setWishlist: (state, action) => {
      state.wishlist = action.payload;
    },
    setTotalWishlistItems: (state, action) => {
      state.totalWishlistItems = action.payload;
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsFetched: (state, action) => {
      state.isFetched = action.payload;
    }
  }
});

export const { setWishlist, setTotalWishlistItems, setLoading, setIsFetched } =
  wishlistSlice.actions;

export const addToWishlistSlice = (productId) => async (dispatch) => {
  const added = await wishlistService.addWishlistItem({ productId });

  if (added) {
    toast.success('Added To Wishlist');
    dispatch(getWishlistSlice());
  }
};

export const getWishlistSlice = () => async (dispatch) => {
  dispatch(setLoading(true));
  const data = await wishlistService.getWishlists();
  if (data) {
    dispatch(setWishlist(data));
    dispatch(setTotalWishlistItems(data.length));
    dispatch(setIsFetched(true));
  }
  dispatch(setLoading(false));
};

export const removeWishlistItemSlice = (productId) => async (dispatch) => {
  const deleteWishlistItem = await wishlistService.deleteWishlistItem(
    productId
  );

  if (deleteWishlistItem) {
    await dispatch(getWishlistSlice());
  }
};

export default wishlistSlice.reducer;
