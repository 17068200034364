/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
// import { VerticalHorizontalCenter } from 'styles/utils/Flex';
// import Mainbutton from '../button/Mainbutton';
// import emptyAvatar from 'assets/images/team/empty-avatar.jpg';
// import ImageUploaderContainer from './elements/ImageUploader.styled';
// import DeleteIcon from '@material-ui/icons/Delete';
// import BackupIcon from '@material-ui/icons/Backup';
// import { Iconbutton } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const ImageUploader = ({ source, setSource, name }) => {
  const emptyAvatar =
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png'
  const [previewImg, setPreviewImg] = useState(null)
  const fileInp = useRef(null)

  const onSelectFile = (e) => {
    const file = e.target.files[0]
    if (file) {
      setPreviewImg(URL.createObjectURL(file))
      name && setSource(name, file)
      setSource(file)
      //   setSource(file);
    }
  }

  return (
    <div className="image-upload-cont">
      <div className="image-cont">
        <button
          type="button"
          className="dlt-image"
          onClick={() => {
            setSource(name, emptyAvatar)
            setPreviewImg(null)
          }}
          
        >
          {/* <DeleteIcon color="error" /> */}
          <div>X</div>
        </button>
        <img    style={{borderRadius: '0px'}} 
          src={previewImg || source || emptyAvatar}
          onClick={() => {
            fileInp.current.click()
          }}
          alt="profile-pic"
        />
      </div>

      <div className="secondary-cont-upload">
        <label htmlFor="file-selector">
          {/* <button
            variant="contained"
            color="primary"
            padding="10px 25px"
            fontSize="18px"
            fontWeight="500"
            onClick={() => {
              fileInp.current.click()
            }}
          >
            Upload
            <BackupIcon style={{ marginLeft: 10 }} />
          </button> */}
        </label>
        <input
          type="file"
          ref={fileInp}
          name={name}
          hidden
          onChange={onSelectFile}
          accept="image/*"
        />
        {/* <p style={{ marginTop: 20 }}>
          Allowed JPG, GIF or PNG. Max size of 2MB
        </p> */}
      </div>
    </div>
  )
}

export default ImageUploader
