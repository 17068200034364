/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify'
// import ReactS3Client from 'util/s3Bucket'
import S3 from 'react-aws-s3'

// const multipleImageUpload = async (images) => {
//   const imagesArray = await images.reduce(async (result, el) => {
//     if (el.originFileObj) {
//       try {
//         const asyncResult = await ReactS3Client.uploadFile(
//           el.originFileObj

//           // formValues.images[1].originFileObj.name,
//         )
//         if (asyncResult) {
//           ;(await result).push(asyncResult.location)
//         }
//       } catch (err) {
//         // (await result).push(result.location);
//         toast.error('Cannot upload Newly added Images')

//         //console.log(err.message)
//       }
//     } else {
//       ;(await result).push(el.url)
//     }

//     return result
//   }, [])

//   return imagesArray
// }

const multipleImageUpload = async (images, folder) => {
  window.Buffer = window.Buffer || require('buffer').Buffer
  const imagesArray = await images.reduce(async (result, el) => {
    if (el.originFileObj) {
      const config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        dirName: folder /* optional */,
        region: process.env.REACT_APP_S3_REGION,
        accessKeyId: process.env.REACT_APP_S3_ACCESSKEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
      }

      // const newFileName = 'test-file';
      const ReactS3Client = new S3(config)
      try {
        const asyncResult = await ReactS3Client.uploadFile(
          el.originFileObj
          // formValues.images[1].originFileObj.name,
        )
        if (asyncResult) {
          ;(await result).push(asyncResult.location)
        }
      } catch (err) {
        // (await result).push(result.location);
        toast.error('Cannot upload Newly added Images')
      }
    } else {
      ;(await result).push(el.url)
    }

    return result
  }, [])

  return imagesArray
}

const singleImageUploader = async (imgValue, folder) => {
  window.Buffer = window.Buffer || require('buffer').Buffer
  if (imgValue?.name) {
    const config = {
      bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
      dirName: folder /* optional */,
      region: process.env.REACT_APP_S3_REGION,
      accessKeyId: process.env.REACT_APP_S3_ACCESSKEY_ID,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    }

    // const newFileName = 'test-file';
    const ReactS3Client = new S3(config)

    try {
      const asyncResult = await ReactS3Client.uploadFile(
        imgValue
        // Math.random() * 10,
        // 'Profile'
        // formValues.images[1].originFileObj.name,
      )
      imgValue = asyncResult.location

      //console.log(asyncResult, 'show-image-url')
    } catch (err) {
      // (await result).push(result.location);
      toast.error('Cannot upload Newly added Images')

      //console.log(err, 'the-errooorrrroror')
    }
  }
  return imgValue
}

export { multipleImageUpload, singleImageUploader }
