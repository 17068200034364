import instance from 'api'
const settingService = {}

settingService.getSettings = async function () {
  try {
    const {
      data: { data },
    } = await instance.get('/api/v1/setting')
    const resData = data
    return resData
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

export default settingService
