// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import cartService from 'service/cart';
import Popup from 'react-popup';
// import { notification } from 'antd';
// import history from 'utils/history';
// TODO -> Handle api errors

export const CartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: [],
    cartTotal: 0,
    totalMrp: 0,
    discount: 0,
    totalCartItems: 0,
    loading: false,
    isFetched: false
  },
  reducers: {
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTotalCartItems: (state, action) => {
      state.totalCartItems = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    updateCartTotal: (state, action) => {
      const { operator, price, mrpPrice } = action.payload;

      //console.log(action.payload, 'foinalll');

      if (operator === 'add') {
        state.cartTotal = state.cartTotal + price;
        state.totalMrp = state.totalMrp + mrpPrice;
        state.discount = state.discount + Math.abs(mrpPrice - price)
      } else {
        if (state.cartTotal >= 0) {
          state.cartTotal = state.cartTotal - price;
          state.totalMrp = state.totalMrp - mrpPrice;
          state.discount = Math.abs(state.discount - Math.abs(mrpPrice - price))
        }
      }
    },
    setCartTotal: (state, action) => {
      //console.log("changed cart total to", action.payload)
      state.cartTotal = action.payload;
    },
    setTotalMrp: (state, action) => {
      state.totalMrp = action.payload;
    },
    setIsFetched: (state, action) => {
      state.isFetched = action.payload;
    },
    clearCart: (state) => {
      state.cart = [];
      state.cartTotal = 0;
      state.totalMrp = 0;
      state.discount = 0;
      state.totalCartItems = 0;
      // state.loading = false;
      // state.isFetched = false;
    }
  }
});

export const {
  setCart,
  setLoading,
  updateCartTotal,
  setCartTotal,
  setTotalMrp,
  setDiscount,
  setTotalCartItems,
  setIsFetched,
  clearCart
} = CartSlice.actions;

export const addToCartSlice =
  (productId, quantityCounter) => async (dispatch) => {
    const added = await cartService.addCartItem({ productId });

    if (added && quantityCounter) {
      await cartService.updateCartQty(productId, {
        quantity: quantityCounter
      });
    }

    if (added) {
      toast.success('Added To Cart');
      dispatch(getCartSlice());
    }
    if (added) {
      Popup.alert('I am alert, nice to meet you');
    }
  };

export const getCartSlice = () => async (dispatch) => {
  dispatch(setLoading(true));
  const data = await cartService.getCart();

  if (data) {
    dispatch(setCart(data));
    dispatch(setTotalCartItems(data.length));

    let sum = 0;
    let discount = 0;
    data.map((item) => {
      //console.log(item, 'carttt');
      sum += item.quantity * item.product.price;
      discount += item.quantity * item.product.mrpPrice;
    });
    dispatch(setCartTotal(sum));
    dispatch(setTotalMrp(discount));
    dispatch(setDiscount(Math.abs(sum - discount)))
    dispatch(setIsFetched(true));
  }
  dispatch(setLoading(false));
};

export const removeCartItemSlice =
  (productId, qty, price, mrpPrice) => async (dispatch, getState) => {
    const { cartTotal, totalMrp, discount } = getState().cart;
    const deleteCartItem = await cartService.deleteCartItem(productId);

    if (deleteCartItem) {
      await dispatch(setCartTotal(cartTotal - qty * price));
      await dispatch(setTotalMrp(totalMrp - qty * mrpPrice));
      await dispatch(setDiscount(Math.abs(discount - qty * Math.abs(mrpPrice - price))));

      await dispatch(getCartSlice());
    }
  };

// const onRemoveItem = async (product, qty, price) => {
//   try {
//     const { data } = await removeFromCartApi({ productId: product._id })

//     if (data) {
//       //console.log(product, 'mydatass')
//       setCartTotal(cartTotal - qty * price)

//       // setCartItemCount(data.data.length)
//       setCartItems(data.data)
//     } else {
//       setCartItems([])
//     }

//     // if (data) {
//     //   toast.success('Remove Item Success')
//     //   const restItem = cartItems.filter((cur) => {
//     //     //console.log(cur.product_.id, 'hetttt')
//     //     return cur.product_.id !== id
//     //   })
//     //   setCartItems(restItem)
//     // }
//   } catch (err) {
//     toast.error(err.response.data.error)
//   }
//   // setCartItems(restItem)

//   // localStorage.setItem('cartItems', JSON.stringify(restItem))
// }

// export const getCategory = (id) => async (dispatch) => {
//   dispatch(setLoading(true))
//   const data = await categoryService.getCategoryById(id)
//   if (data) {
//     dispatch(setCategory(data))
//   }
//   dispatch(setLoading(false))
// }

export default CartSlice.reducer;
