import instance from 'api';

const homeWidgetService = {};

homeWidgetService.getHomeWidget = async function () {
  try {
    const {
      data: { data }
    } = await instance.get('/api/v1/widget/tab/home/Web');
    // const availableData = data.filter(
    //   (cur) => cur.status !== 'Deleted' && cur.status !== 'Hold'
    // )
    return data;
  } catch (err) {
    //console.log(err, 'show-err');
  }
};

homeWidgetService.getHomeWidgetById = async function (
  id,
  paginationQuery = '',
  filterQuery = ''
) {
  try {
    const { data } = await instance.get(
      `/api/v1/widget/tab/home/id/${id}?${paginationQuery}${filterQuery}`
    );
    // const availableData = data.filter(
    //   (cur) => cur.status !== 'Deleted' && cur.status !== 'Hold'
    // )
    return data;
  } catch (err) {
    //console.log(err, 'show-err');
  }
};

// homeWidgetService.getProductById = async function (id) {
//   try {
//     const {
//       data: { data },
//     } = await instance.get(`/api/v1/products/${id}/public`)
//     return data
//   } catch (err) {
//     //console.log(err, 'show-err')
//   }
// }

export default homeWidgetService;
