/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import useClickOutside from '../../util/outsideClick';

// Svg Icons
import cartIcon from 'assets/imgs/theme/icons/icon-cart.svg';
import facebookIcon from 'assets/imgs/theme/icons/icon-facebook.svg';
import twitterIcon from 'assets/imgs/theme/icons/icon-twitter.svg';
import instagramIcon from 'assets/imgs/theme/icons/icon-instagram.svg';
import pinterestIcon from 'assets/imgs/theme/icons/icon-pinterest.svg';
import youtubeIcon from 'assets/imgs/theme/icons/icon-youtube.svg';
import logoIcon from 'assets/imgs/theme/logo.png';
import whatsapp from 'assets/imgs/theme/icons/whatsapp.svg';
import { useEffect } from 'react';
// import { getCategories } from 'redux/slices/categorySlice'
import { useDispatch, useSelector } from 'react-redux';
import Search from '../common/Search';
import {
  getProductsTemplateBySearch,
  setFilters
} from 'redux/slices/productTemplateSlice';
import { getCategories, setCategories } from 'redux/slices/categorySlice';
import OutsideClickHandler from 'react-outside-click-handler';
import SearchSuggestion from 'components/common/SearchSuggestion';
import { setLogout } from 'redux/slices/authSlice';
import Contact from 'assets/imgs/theme/icons/contact.svg';
import { getSettings } from 'redux/slices/settingSlice';
import categoryService from 'service/category';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
const { confirm } = Modal;

const MobileMenu = ({ isToggled, toggleClick }) => {
  const [isActive, setIsActive] = useState({
    status: false,
    key: ''
  });
  const [allCategories, setAllCategories] = useState([]);
  const { categories } = useSelector((state) => state.category);
  const [searchTerm, setSearchTerm] = useState('');
  const { settings, isFetched: isSettingsFetched } = useSelector(
    (state) => state.setting
  );
  const [isSuggestionShown, setIsSuggestionShown] = useState(false);
  const { informations, isFetched: isInformationFetched } = useSelector(
    (state) => state.information
  );
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (page > 1) {
      dispatch(
        getProductsTemplateBySearch(
          `page=${page}&limit=20`,
          `&search=${searchTerm}`
        )
      );
    }
  }, [page]);
  const { filters, searchSuggestions } = useSelector(
    (state) => state.productTemplate
  );

  const { authorized } = useSelector((state) => state.auth);
  // const { categories } = useSelector((state) => state.category)

  // eslint-disable-next-line no-unused-vars
  const handleToggle = (key) => {
    if (isActive.key === key) {
      setIsActive({
        status: false
      });
    } else {
      setIsActive({
        status: true,
        key
      });
    }
  };
  useEffect(() => {
    !isSettingsFetched && dispatch(getSettings());
  }, [isSettingsFetched]);

  let domNode = useClickOutside(() => {
    setIsActive({
      status: false
    });
  });

  // const [initialCategory, setInitialCategory] = useState([])
  // eslint-disable-next-line no-unused-vars

  // Newest
  // const unflatten = (array, parent, tree) => {
  //   tree = typeof tree !== 'undefined' ? tree : []
  //   parent = typeof parent !== 'undefined' ? parent : { id: 0 }
  //   const children = array.filter((child) => {
  //     return child.parent === parent.id
  //   })
  //   if (children.length !== 0) {
  //     if (parent.id === 0) {
  //       tree = children
  //     } else {
  //       parent.children = children
  //     }
  //     children.forEach((child) => {
  //       unflatten(array, child)
  //     })
  //   }

  //   return tree
  // }

  // // eslint-disable-next-line no-unused-vars
  // const generateCategoryTree = (categories, id) => {
  //   const categoriesFormatted = categories.map((item) => {
  //     if ((id && id !== item._id) || !id) {
  //       const parent =
  //         typeof item.parent === 'object' && item.parent !== null
  //           ? item.parent._id
  //           : item.parent
  //       return {
  //         title: item.name,
  //         value: item._id,
  //         key: item._id,
  //         id: item._id,
  //         parent: item.parent === null ? 0 : parent,
  //       }
  //     }
  //     return {}
  //   })
  //   const tree = unflatten(categoriesFormatted)
  //   return tree
  // }

  // useEffect(() => {
  //   fetch(
  //     `${process.env.REACT_APP_BASE_URL}/api/catalog/v1/category?status=active&sort[priorityOrder]=1`
  //   )
  //     .then((resp) => resp.json())
  //     .then(({ data }) => {
  //       const cat = data.filter((cur) => cur.priorityOrder === 1)
  //       setAllCategories(cat)

  //       const tree = generateCategoryTree(cat)
  //       //console.log(tree, 'answer')

  //       setInitialCategory(tree.slice(0, 10))
  //     })
  // }, [])

  const createCategoryList = (categories, parentId = null) => {
    const categoryList = [];
    let category;
    if (parentId == null) {
      category = categories.filter((cat) => !cat?.parentId);
    } else {
      category = categories.filter((cat) => cat?.parentId === parentId);
    }
    // eslint-disable-next-line prefer-const
    for (let cate of category) {
      categoryList.push({
        id: cate.id,
        title: cate.name,
        value: cate.id,
        key: cate.id,
        children: createCategoryList(categories, cate.id)
      });
    }

    return categoryList;
  };

  useEffect(() => {
    if (categories) {
      const tree = createCategoryList(categories);

      const sortByInnerChild = tree.map((first) => {
        if (first.children?.length === 0) {
          return { ...first, nestedChildrenLvl: 0 };
        } else if (first.children?.length > 0) {
          let findInner;
          first.children.map((second) => {
            if (second.children?.length === 0) {
              findInner = 1;
            } else if (second.children?.length > 0) {
              findInner = 2;
            }
          });
          return { ...first, nestedChildrenLvl: findInner };
        }
      });

      setAllCategories(sortByInnerChild.slice(0, 8));
    }
  }, [categories]);

  const addTofilter = (catId) => {
    dispatch(setFilters([{ filterName: 'categoryId', value: catId }]));
    navigate(`/products?categoryId=${catId}`);
    setIsActive({
      status: false
    });
    toggleClick();
  };

  // useEffect(() => {
  //   dispatch(getCategories())

  // }, [])

  const getCategories = async () => {
    const data = await categoryService.getCategories(
      'orderByHomePriority=true' & 'orderByLevel=true'
    );

    if (data) {
      dispatch(setCategories(data));
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const onSelect = (productTemplateSlug, productId, productType, category) => {
    if (productType === 'Medicine') {
      navigate(`/${category}/${productTemplateSlug}/${productId}/medicine`);
    } else {
      navigate(`/${category}/${productTemplateSlug}/${productId}`);
    }
  };

  const onSearchSubmit = (text) => {
    setIsSuggestionShown(false);

    // const addSearchIfNotThereOrUpdateIfItsThere = (arr, newObj) => [
    //   ...arr.filter((o) => o.filterName !== newObj.filterName),
    //   { ...newObj },
    // ]

    // const res = addSearchIfNotThereOrUpdateIfItsThere(filters, {
    //   filterName: 'search',
    //   value: text || searchTerm,
    // })

    // dispatch(setFilters(res))

    // navigate(`/products?search=${text || searchTerm}&selected=search`)

    dispatch(
      setFilters([
        { filterName: 'search', value: searchTerm },
        { filterName: 'selected', value: 'search' }
      ])
    );

    navigate(`/products?search=${searchTerm}&selected=search`);

    toggleClick();
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setIsSuggestionShown(true);

    dispatch(getProductsTemplateBySearch(`&search=${e.target.value}`));
  };

  const handleInput = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchSubmit();
    }
  };

  const onNavigate = (path) => {
    navigate(path);
    toggleClick();
  };

  const showConfirm = () => {
    toggleClick();
    confirm({
      centered: true,
      title: 'Do you want to logout?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        dispatch(setLogout());
      },
      onCancel() { }
    });
  };

  return (
    <>
      <div
        className={
          isToggled
            ? 'mobile-header-active mobile-header-wrapper-style sidebar-visible'
            : 'mobile-header-active mobile-header-wrapper-style'
        }
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-top">
            <div className="mobile-header-logo">
              <Link to="/">
                <img src={logoIcon} alt="logo" />
              </Link>
            </div>
            <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
              <button
                className="close-style search-close"
                onClick={toggleClick}
              >
                <i className="icon-top"></i>
                <i className="icon-bottom"></i>
              </button>
            </div>
          </div>
          <div className="mobile-header-content-area">
            <div className="mobile-search search-style-3 mobile-header-border">
              <form>
                <input
                  type="text"
                  autoComplete="off"
                  name="searchQuery"
                  placeholder="Search for Medicines & Wellness Products"
                  onKeyDown={handleInput}
                  value={searchTerm}
                  onChange={onSearchChange}
                  className="search-input-mobile"
                  style={{ fontSize: '12px' }}
                />
                <button type="submit" disabled={!searchTerm}>
                  <i className="fi-rs-search"></i>
                </button>
                <OutsideClickHandler
                  onOutsideClick={() => setIsSuggestionShown(false)}
                >
                  <SearchSuggestion
                    searchSuggestions={searchSuggestions}
                    onSelect={onSelect}
                    searchTerm={searchTerm}
                    setIsSuggestionShown={setIsSuggestionShown}
                    isSuggestionShown={isSuggestionShown}
                  />
                </OutsideClickHandler>
              </form>
            </div>
            <div className="mobile-menu-wrap mobile-header-border">
              <div className="main-categori-wrap mobile-header-border">
                <div className="categori-dropdown-wrap categori-dropdown-active-small">
                  <ul>
                    <li>
                      <Link to="/shop-grid-right">
                        <i className="evara-font-dress"></i>
                        Women's Clothing
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop-grid-right">
                        <i className="evara-font-tshirt"></i>
                        Men's Clothing
                      </Link>
                    </li>
                    <li>
                      {' '}
                      <Link to="/shop-grid-right">
                        <i className="evara-font-smartphone"></i> Cellphones
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop-grid-right">
                        <i className="evara-font-desktop"></i>
                        Computer & Office
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop-grid-right">
                        <i className="evara-font-cpu"></i>
                        Consumer Electronics
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop-grid-right">
                        <i className="evara-font-home"></i>
                        Home & Garden
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop-grid-right">
                        <i className="evara-font-high-heels"></i>
                        Shoes
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop-grid-right">
                        <i className="evara-font-teddy-bear"></i>
                        Mother & Kids
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop-grid-right">
                        <i className="evara-font-kite"></i>
                        Outdoor fun
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <nav>
                <ul className="mobile-menu" ref={domNode}>
                  <li onClick={() => onNavigate('/categories')}>
                    <i className="fi-rs-apps mr-10"></i>

                    <Link to={`/categories`}>Browse Categories</Link>
                  </li>
                  <li onClick={() => onNavigate('/')}>
                    <i className="fi-rs-home mr-10"></i>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li onClick={() => onNavigate('/medicines')}>
                    <i className="fi-rs-medicine mr-10"></i>
                    <Link to={`/medicines`}>Medicine</Link>
                  </li>

                  {authorized ? (
                    <>
                      {/* <li
                        onClick={() => {
                          navigate('/account#dashboard')
                          toggleClick()
                        }}
                      >
                        <Link to="/account#dashboard">
                          <i className="fi-rs-settings-sliders mr-10"></i>
                          Dashboard
                        </Link>
                      </li> */}

                      <li onClick={() => onNavigate('/cart')}>
                        <i className="fi-rs-shopping-cart mr-10"></i>

                        <Link to="/cart">View Cart</Link>
                      </li>
                      <li onClick={() => onNavigate('/wishlist')}>
                        <i className="fi-rs-heart mr-10"></i>
                        <Link to="/wishlist">My Wishlist</Link>
                      </li>

                      <li onClick={() => onNavigate('/account/my-address')}>
                        <i className="fi-rs-marker mr-10"></i>
                        <Link to="/account/my-address">My Address</Link>
                      </li>
                      <li
                        onClick={() => onNavigate('/account/account-details')}
                      >
                        <i className="fi-rs-user mr-10"></i>
                        <Link to="/account/account-details">
                          Account details
                        </Link>
                      </li>
                      {authorized && (
                        <li
                          onClick={() => onNavigate('/account/update-password')}
                        >
                          <i className="fi-rs-password mr-10"></i>
                          <Link to="/account/update-password">
                            Update Password
                          </Link>
                        </li>
                      )}

                      <li onClick={() => onNavigate('/account/prescription')}>
                        <i className="fi-rs-plus mr-10"></i>
                        <Link to="/account/prescription">Prescriptions</Link>
                      </li>
                      <li onClick={() => onNavigate('/account/orders')}>
                        <i className="fi-rs-shopping-bag mr-10"></i>
                        <Link to="/account/orders">Orders</Link>
                      </li>
                      <li onClick={() => onNavigate('/account/wallet')}>
                        <i className="fi-rs-money mr-10"></i>
                        <Link to="/account/wallet">Wallet</Link>
                      </li>
                      <li onClick={() => onNavigate('/contact')}>
                        <i className="fi-rs-headset mr-10"></i>
                        <Link to="/contact">Contact Us</Link>
                      </li>

                      <li onClick={showConfirm}>
                        <i className="fi-rs-sign-out mr-10"></i>
                        <Link to="#/">Sign out</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        onClick={() => {
                          navigate('/login');
                          toggleClick();
                        }}
                      >
                        <i className="fi-rs-user mr-10"></i>
                        <Link to="/login">Login/Register</Link>
                      </li>
                      <li>
                        <i className="fi-rs-user  mr-10"></i>
                        <Link
                          to="#/"
                          onClick={() =>
                            (window.location.href =
                              process.env.REACT_APP_DEV_MODE == "Live"
                                ? process.env.REACT_APP_VENDOR_SITE_LINK_LIVE
                                : process.env.REACT_APP_VENDOR_SITE_LINK)
                          }
                          target="_blank"
                        >
                          Become Vendor
                        </Link>
                      </li>
                      <li>
                        <i className="fi-rs-shopping-cart mr-10"></i>
                        <Link to="/cart">View Cart</Link>
                      </li>
                      <li>
                        <i className="fi-rs-heart mr-10"></i>
                        <Link to="/wishlist">My Wishlist</Link>
                      </li>
                    </>
                  )}

                  {/* {allCategories?.map((cur) => {
                    return cur.nestedChildrenLvl === 0 ? (
                      <li key={cur.id} onClick={() => addTofilter(cur.id)}>
                        <Link to={`/products?categoryId=${cur.id}`}>
                          {cur.title}
                        </Link>
                      </li>
                    ) : cur.nestedChildrenLvl === 1 ? (
                      <li
                        className={
                          isActive.key === 1
                            ? 'menu-item-has-children active'
                            : 'menu-item-has-children'
                        }
                      >
                        <span
                          className="menu-expand"
                          onClick={() => handleToggle(1)}
                        >
                          <i className="fi-rs-angle-small-down"></i>
                        </span>
                        <Link
                          to={`/products?categoryId=${cur.id}`}
                          onClick={() => addTofilter(cur.id)}
                        >
                          {cur.title}
                        </Link>
                        <ul
                          className={isActive.key === 1 ? 'dropdown' : 'd-none'}
                        >
                          {cur.children?.map((child1) => (
                            <li key={child1.id}>
                              <Link
                                to={`/products?categoryId=${child1.id}`}
                                onClick={() => addTofilter(child1.id)}
                              >
                                {child1.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : null
                    // <li key={cur.id}>
                    //   <Link
                    //     to={`/products?categoryId=${cur.id}`}
                    //     onClick={() => addTofilter(cur.id)}
                    //   >
                    //     {cur.title}
                    //     <i className="fi-rs-angle-down"></i>
                    //   </Link>
                    //   <ul className="sub-menu">
                    //     {cur.children?.map((child1) => (
                    //       <li key={child1.id}>
                    //         <Link
                    //           to={`/products?categoryId=${child1.id}`}
                    //           onClick={() => addTofilter(child1.id)}
                    //         >
                    //           {child1.title}
                    //         </Link>
                    //       </li>
                    //     ))}
                    //   </ul>
                    // </li>
                    // cur.nestedChildrenLvl === 2 ? (
                    //   <li className="position-static" key={cur.id}>
                    //     <Link
                    //       to={`/products?categoryId=${cur.id}`}
                    //       onClick={() => addTofilter(cur.id)}
                    //     >
                    //       {cur.title}
                    //       <i className="fi-rs-angle-down"></i>
                    //     </Link>

                    //     <ul className="mega-menu">
                    //       {cur.children?.map((child1) => (
                    //         <li
                    //           key={child1.id}
                    //           className="sub-mega-menu sub-mega-menu-width-22"
                    //         >
                    //           <Link
                    //             className="menu-title"
                    //             to={`/products?categoryId=${child1.id}`}
                    //             onClick={() => addTofilter(child1.id)}
                    //           >
                    //             {child1.title}
                    //           </Link>
                    //           <ul>
                    //             {child1.children?.map((child2) => (
                    //               <li key={child2.id}>
                    //                 <Link
                    //                   to={`/products?categoryId=${child2.id}`}
                    //                   onClick={() => addTofilter(child2.id)}
                    //                 >
                    //                   {child2.title}
                    //                 </Link>
                    //               </li>
                    //             ))}
                    //           </ul>
                    //         </li>
                    //       ))}
                    //     </ul>
                    //   </li>
                    // ) : null
                  })} */}

                  {/* {categories?.slice(0, 7)?.map((cur) => (
                    <li
                      key={cur.id}
                      onClick={() => navigate(`/products?categoryId=${cur.id}`)}
                    >
                      <Link to={`/products?categoryId=${cur.id}`}>
                        {cur.name}
                      </Link>
                    </li>
                  ))} */}

                  {/* <li
                    className={
                      isActive.key === 1
                        ? 'menu-item-has-children active'
                        : 'menu-item-has-children'
                    }
                  >
                    <span
                      className="menu-expand"
                      onClick={() => handleToggle(1)}
                    >
                      <i className="fi-rs-angle-small-down"></i>
                    </span>
                    <Link to="/index">
                      <a>Home</a>
                    </Link>
                    <ul className={isActive.key === 1 ? 'dropdown' : 'd-none'}>
                      <li>
                        <Link to="/index">
                          <a>Home 1</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/index-2">
                          <a>Home 2</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/index-3">
                          <a>Home 3</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/index-4">
                          <a>Home 4</a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      isActive.key === 2
                        ? 'menu-item-has-children active'
                        : 'menu-item-has-children'
                    }
                  >
                    <span
                      className="menu-expand"
                      onClick={() => handleToggle(2)}
                    >
                      <i className="fi-rs-angle-small-down"></i>
                    </span>
                    <Link to="/shop-grid-right">shop</Link>
                    <ul className={isActive.key === 2 ? 'dropdown' : 'd-none'}>
                      <li>
                        <Link to="/shop-grid-right">
                          Shop Grid – Right Sidebar
                        </Link>
                      </li>
                      <li>
                        <Link to="/shop-grid-left">
                          Shop Grid – Left Sidebar
                        </Link>
                      </li>
                      <li>
                        <Link to="/shop-list-right">
                          Shop List – Right Sidebar
                        </Link>
                      </li>
                      <li>
                        <Link to="/shop-list-left">
                          Shop List – Left Sidebar
                        </Link>
                      </li>
                      <li>
                        <Link to="/shop-fullwidth">Shop - Wide</Link>
                      </li>
                      <li>
                        <Link to="/shop-filter">Shop – Filter</Link>
                      </li>
                      <li>
                        <Link to="/wishlist">Shop – Wishlist</Link>
                      </li>
                      <li>
                        <Link to="/cart">Shop – Cart</Link>
                      </li>
                      <li>
                        <Link to="/shop-checkout">Shop – Checkout</Link>
                      </li>
                      <li>
                        <Link to="/shop-compare">Shop – Compare</Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      isActive.key === 3
                        ? 'menu-item-has-children active'
                        : 'menu-item-has-children'
                    }
                  >
                    <span
                      className="menu-expand"
                      onClick={() => handleToggle(3)}
                    >
                      <i className="fi-rs-angle-small-down"></i>
                    </span>
                    <Link to="#">
                      <a>Mega menu</a>
                    </Link>
                    <ul className={isActive.key === 3 ? 'dropdown' : 'd-none'}>
                      <li className="menu-item-has-children">
                        <span className="menu-expand"></span>
                        <Link to="#">
                          <a>Women's Fashion</a>
                        </Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="/shop-product-right">
                              <a>Dresses</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-product-right">
                              <a>Blouses & Shirts</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-product-right">
                              <a>Hoodies & Sweatshirts</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-product-right">
                              <a>Women's Sets</a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <span className="menu-expand"></span>
                        <Link to="#">
                          <a>Men's Fashion</a>
                        </Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="/shop-product-right">
                              <a>Jackets</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-product-right">
                              <a>Casual Faux Leather</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-product-right">
                              <a>Genuine Leather</a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <span className="menu-expand"></span>
                        <Link to="#">
                          <a>Technology</a>
                        </Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="/shop-product-right">
                              <a>Gaming Laptops</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-product-right">
                              <a>Ultraslim Laptops</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-product-right">
                              <a>Tablets</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-product-right">
                              <a>Laptop Accessories</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-product-right">
                              <a>Tablet Accessories</a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      isActive.key === 4
                        ? 'menu-item-has-children active'
                        : 'menu-item-has-children'
                    }
                  >
                    <span
                      className="menu-expand"
                      onClick={() => handleToggle(4)}
                    >
                      <i className="fi-rs-angle-small-down"></i>
                    </span>
                    <Link to="/blog-category-fullwidth">
                      <a>Blog</a>
                    </Link>
                    <ul className={isActive.key === 4 ? 'dropdown' : 'd-none'}>
                      <li>
                        <Link to="/blog-category-grid">
                          <a>Blog Category Grid</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-category-list">
                          <a>Blog Category List</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-category-big">
                          <a>Blog Category Big</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-category-fullwidth">
                          <a>Blog Category Wide</a>
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <span className="menu-expand"></span>
                        <Link to="#">
                          <a>Single Product Layout</a>
                        </Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="/blog-post-left">
                              <a>Left Sidebar</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog-post-right">
                              <a>Right Sidebar</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog-post-fullwidth">
                              <a>No Sidebar</a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      isActive.key === 5
                        ? 'menu-item-has-children active'
                        : 'menu-item-has-children'
                    }
                  >
                    <span
                      className="menu-expand"
                      onClick={() => handleToggle(5)}
                    >
                      <i className="fi-rs-angle-small-down"></i>
                    </span>
                    <Link to="#">Pages</Link>
                    <ul className={isActive.key === 5 ? 'dropdown' : 'd-none'}>
                      <li>
                        <Link to="/page-about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/page-contact">Contact</Link>
                      </li>
                      <li>
                        <Link to="/account">My Account</Link>
                      </li>
                      <li>
                        <Link to="/login">login/register</Link>
                      </li>
                      <li>
                        <Link to="/page-purchase-guide">Purchase Guide</Link>
                      </li>
                      <li>
                        <Link to="/page-privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/page-terms">Terms of Service</Link>
                      </li>
                      <li>
                        <Link to="/page-404">404 Page</Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      isActive.key === 6
                        ? 'menu-item-has-children active'
                        : 'menu-item-has-children'
                    }
                  >
                    <span
                      className="menu-expand"
                      onClick={() => handleToggle(6)}
                    >
                      <i className="fi-rs-angle-small-down"></i>
                    </span>
                    <Link to="#">
                      <a>Language</a>
                    </Link>
                    <ul className={isActive.key === 6 ? 'dropdown' : 'd-none'}>
                      <li>
                        <Link to="#">
                          <a>English</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <a>French</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <a>German</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <a>Spanish</a>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </nav>
            </div>
            {/* <div className="mobile-header-info-wrap mobile-header-border">
              <div className="single-mobile-header-info mt-30">
                <Link to="/page-contact">
                  <a> Our location </a>
                </Link>
              </div>
              <div className="single-mobile-header-info">
                <Link to="/login">
                  <a>Log In / Sign Up </a>
                </Link>
              </div>
              <div className="single-mobile-header-info">
                <Link to="#">
                  <a>(+01) - 2345 - 6789 </a>
                </Link>
              </div>
            </div> */}
            {/* <div className="mobile-social-icon">
            
              <Link
                to="#"
                onClick={() => (window.location.href = settings.facebookUrl)}
              >
                <img src={facebookIcon} alt="facebook" />
              </Link>
              <Link
                to="#"
                onClick={() => (window.location.href = settings.twitterUrl)}
              >
                <img src={twitterIcon} alt="twitter" />
              </Link>
              <Link
                to="#"
                onClick={() => (window.location.href = settings.instagramUrl)}
              >
                <img src={instagramIcon} alt="instagram" />
              </Link>

              <Link
                to="#"
                onClick={() =>
                  (window.location.href = `https://wa.me/${settings.whatsappNo}`)
                }
              >
                <img src={whatsapp} alt="whatsapp" />
              </Link>
              <br></br>
            </div> */}
            {/* <ul className="footer-list mt-10">
              {informations?.map((info) => (
                <li key={info.id}>
                  <Link to={`/information/${info.id}`}>{info.name}</Link>
                </li>
              ))}
            </ul> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
