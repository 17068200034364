/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Breadcrumb from './Breadcrumb';

import Header from './Header';
import MobileMenu from './MobileMenu';

const Layout = ({
  children,
  headerStyle,
  showCategories = true,
  parent,
  sub,
  subLink,
  isSubFilterReset,
  subChild,
  subChildLink,
  subChildLinkFilter,
  subSubChild,
  noBreadcrumb,
  subChildLinkFilterId,
  minMainHeight = '100vh',
  mainClass = '',
  isShowHeader = true
}) => {
  //console.log('reloaded');
  const [isToggled, setToggled] = useState(false);
  const toggleClick = () => {
    setToggled(!isToggled);
    isToggled
      ? document.querySelector('body').classList.remove('mobile-menu-active')
      : document.querySelector('body').classList.add('mobile-menu-active');
  };

  return (
    <>
      {/* <Head>
                <title>Nest - Redux NextJS eCommerce Template</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head> */}

      {isToggled && (
        <div className='body-overlay-1' onClick={toggleClick}></div>
      )}

      {isShowHeader && (
        <Header
          headerStyle={headerStyle}
          isToggled={isToggled}
          toggleClick={toggleClick}
          showCategories={showCategories}
        />
      )}
      <MobileMenu isToggled={isToggled} toggleClick={toggleClick} />
      <main className={`main ${mainClass}`}>
        {noBreadcrumb && (
          <Breadcrumb
            parent={parent}
            sub={sub}
            subChild={subChild}
            subSubChild={subSubChild}
            noBreadcrumb={noBreadcrumb}
            subLink={subLink}
            isSubFilterReset={isSubFilterReset}
            subChildLink={subChildLink}
            subChildLinkFilter={subChildLinkFilter}
            subChildLinkFilterId={subChildLinkFilterId}
          />
        )}
        {children}
      </main>
    </>
  );
};

export default Layout;
