/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import { authentication } from 'firebaseconfig'
import { useDispatch } from 'react-redux';
import {
  setLogout,
  setToken,
  setUser,
  setUserLoading
} from 'redux/slices/authSlice';
import WOW from 'wowjs';
import { toast } from 'react-toastify';
import { getUserProfileApi } from 'api/user';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { authentication, getDeviceToken } from 'firebaseconfig';
import getFBError from 'util/firebaseErrors';
import authService from 'service/auth';
// import { getAllProductsTemplate } from 'redux/slices/productTemplateSlice'
// import authService from 'service/auth'
// import { sendEmailVerification } from 'firebase/auth'
// import storage from 'util/localStorage'

const BeforeAppLoad = ({ children }) => {
  const [deviceToken, setDeviceToken] = useState('');

  const dispatch = useDispatch();

  const emailVerificationHandler = async () => {
    const user = await authentication.currentUser;
    if (user) {
      const isEmailVerified = user.emailVerified;
      const email = user.email;
      if (!isEmailVerified) {
        try {
          sendEmailVerification(authentication.currentUser)
            .then(() => {
              toast.success(
                `Email is sent to ${email}. Click the link to complete your Verification.`
              );
              // Email verification sent!
            })
            .catch((err) => {
              toast.error(
                getFBError(!isNaN(err.code) ? err.message : err.code)
              );
            });
        } catch (err) {
          toast.error(getFBError(err.code));
        }
      } else {
        toast.info(`Email is already verified. Please Login again.`);
      }
    }
  };

  const EmailVerificationToast = () => (
    <div>
      <div className="toast-header">
        <p className="mr-auto">
          Your Email Not Verified <br />
          <b>
            <a href="#/" onClick={emailVerificationHandler}>
              Click here To Verify Your Email
            </a>
          </b>
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    // const user = localStorage.getItem('user')
    // const userParse = JSON.parse(user)

    // if (userParse) {
    //   dispatch(setUser(userParse))
    //   dispatch(setUserLoading(false))
    // } else {
    //   dispatch(setUserLoading(false))
    // }
    const unsubscribe = getAuth().onAuthStateChanged(async (user) => {
      dispatch(setUserLoading(true));
      //console.log(user, 'the-user');
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true);
        //console.log(idTokenResult, 'idTokenResult');
        const userType = idTokenResult.claims.userType;
        dispatch(setToken(idTokenResult.token));

        if (userType === 'Customer') {
          // const { data: sData } = await authService.getUserProfile();
          try {
            const {
              data: { data }
            } = await getUserProfileApi();
            const dispatchingData = {
              userType,
              firstName: data.firstName,
              lastName: data.lastName,
              address: data.address,
              prescriptions: data.prescriptions,
              email: user.email,
              phone: user.phoneNumber,
              displayImage: data.displayImage,
              emailVerified: user.emailVerified,
              phoneVerified: user.phoneNumber ? true : false,
              id: data.id
            };
            dispatch(setUser(dispatchingData));
            dispatch(setToken(idTokenResult.token));
            dispatch(setUserLoading(false));
          } catch (err) {
            toast.error(err.response.data.title);
            //console.log('the-errr', err.response.data);

            dispatch(setLogout());
            dispatch(setUserLoading(false));
          }
        } else if (userType !== 'Admin' && userType !== 'Vendor') {
          // Redux Store
          dispatch(
            setUser({
              firstName: user.displayName?.split(' ')[0] || user.displayName,
              displayImage: user.photoURL,
              lastName: user.displayName?.split(' ')[1] || null,
              email: user.email,
              phone: user.phoneNumber,
              emailVerified: user.emailVerified,
              phoneVerified: user.phoneNumber ? true : false
              // passwordVerified: passwordAvailable?.providerId ? true : false,
            })
          );
          dispatch(setToken(idTokenResult.token));
          dispatch(setUserLoading(false));
        } else {
          toast.error(`${userType} Account Cannot Login`);
          setTimeout(() => {
            dispatch(setLogout());
          }, 2000);
        }

        // const passwordAvailable = user.providerData?.find(
        //   (cur) => cur.providerId === 'password'
        // )

        if (
          user?.email &&
          !user?.phoneNumber &&
          !user.emailVerified &&
          !window.location.pathname?.includes('/user-authdetails-update')
        ) {
          toast.warning(EmailVerificationToast, {
            autoClose: true
          });
        }
        if (user.phoneNumber === '') {
          toast.warning(`You need to Verify your Phone Number`);
        }
      }
      dispatch(setUserLoading(false));
    });

    window.wow = new WOW.WOW({
      live: false
    });

    window.wow.init();

    // Notification setup Firebase
    const fcmToken = getDeviceToken(setDeviceToken);

    //console.log(fcmToken, 'fcmToken');

    // dispatch(getAllProductsTemplate())

    // Clean Up
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (deviceToken) {
      window.localStorage.setItem('deviceToken', deviceToken);
    }
  }, [deviceToken]);

  // useEffect(() => {
  //   // const cart = storage.get('cart') || []
  //   // const wishlist = storage.get('wishlist') || []
  //   // const compare = storage.get('compare') || []
  //   // Also Check Auth State

  //   // Wow Init
  //   // if (typeof window !== 'undefined') {
  //   //   window.WOW = require('wowjs')
  //   // }
  //   // new WOW.WOW().init()

  // }, [])

  return <>{children}</>;
};

export default BeforeAppLoad;
