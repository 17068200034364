/* eslint-disable react/prop-types */
// import { useRouter } from "next/router";
// import { connect } from "react-redux";
// import { updateProductCategory } from "../../../redux/action/productFiltersAction";
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Svg Icons
// import category1 from 'assets/imgs/theme/icons/category-1.svg'
// import category2 from 'assets/imgs/theme/icons/category-2.svg'
// import category3 from 'assets/imgs/theme/icons/category-3.svg'
// import category4 from 'assets/imgs/theme/icons/category-4.svg'
// import category5 from 'assets/imgs/theme/icons/category-5.svg'
import { Link, useNavigate } from 'react-router-dom';
import { setFilters } from 'redux/slices/productTemplateSlice';
import Gradient from 'assets/imgs/icons/blurry-gradient.svg';

const CategoryProduct2 = ({ categories }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const router = useRouter();

  // const removeSearchTerm = () => {
  //     router.push({
  //         pathname: "/products",
  //     });
  // };

  // const selectCategory = (e, category) => {
  //   // e.preventDefault()
  //   // //console.log(category)
  //   // removeSearchTerm();
  //   // updateProductCategory(category);
  //   // router.push({
  //   //     pathname: "/products",
  //   //     query: {
  //   //         cat: category, //
  //   //     },
  //   // });
  // }

  const onCategoryClick = (id, name) => {
    dispatch(
      setFilters([
        { filterName: 'categoryId', value: id },
        { filterName: '', value: id },
        { filterName: 'selected', value: `category-${name}` }
      ])
      // setFilters([
      //   { filterName: 'categoryId', value: id },

      //   { filterName: 'selected', value: `category-${name}` }
      // ])
    );
    navigate(
      `/${name?.replaceAll(/\s+/g, '-')}/filter?categoryId=${id}&selected=category-${name}&categoryTreeIds=${id}`
    );
  };

  //Observer for lazy loading of Images
  const config = {
    rootMargin: '100px 100px 0px 0px',
    threshold: 0,
  }

  useEffect(() => {
    let observer = new window.IntersectionObserver(function (entries, self) {
      //iterate over each entry
      entries.forEach((entry) => {
        //process whether image are intersecting
        if (entry.isIntersecting) {
          //custom function that copies the path to the img from data-src to src
          loadImages(entry.target)
          //image is now in place so stop watching
          self.unobserve(entry.target)
        }
      })
    }, config)

    const imgs = document.querySelectorAll('[data-src]')
    imgs.forEach(img => {
      observer.observe(img);
    })

    return () => {
      imgs.forEach(img => {
        observer.unobserve(img);
      })
    }
  }, [])

  function loadImages(image) {
    image.src = image.dataset.src;
  }

  return (
    <>
      <ul>
        {categories?.map((cur, i) => (
          <li key={i} onClick={() => onCategoryClick(cur.id, cur?.name)}>
            <Link
              to={`/${cur?.name?.replaceAll(/\s+/g, '-')}?categoryId=${cur?.id}&categoryTreeIds=${cur?.id}&selected=category-${cur?.id}`}
            >
              <img
                src={Gradient}
                data-src={cur?.image}
                alt=""
              />
              {cur.name}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CategoryProduct2;
