/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify'

export const errorValidator = (res) => {
  //console.log('my-res', res)
  if (res) {
    if (Array.isArray(res?.errors)) {
      res?.errors?.forEach((cur) => {
        if (cur?.itemName) {
          toast.error(`${cur.type}: ${cur.itemName}`)
        } else if (cur?.type === 'Delivery Not Available') {
          toast.error(`${cur.type}: ${cur.item?.name}`)
        } else if (cur?.item) {
          toast.error(`${cur.type}`)
        }
      })
    } else {
      if (res?.errors) {
        for (const [key, value] of Object.entries(res?.errors)) {
          value.forEach((cur) => {
            toast.error(`${key}: ${cur}`)
          })
        }
      } else {
        if (res.status !== 401) {
          toast.error(res.title)
        }
      }
    }
  }
}
