// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit';
import orderService from 'service/order';

// import { notification } from 'antd';
// import history from 'utils/history';
// TODO -> Handle api errors

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orders: [],
    loading: false
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const { setOrders, setLoading } = orderSlice.actions;

export const getOrders =
  (query = '') =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const data = await orderService.getOrders(query);
    if (data) {
      //console.log(data, 'jdkloeh');
      dispatch(setOrders(data));
    }
    dispatch(setLoading(false));
  };

export default orderSlice.reducer;
