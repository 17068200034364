import React from 'react';
import axios from 'axios';
import { errorValidator } from 'util/errorValidator';
import { toast } from 'react-toastify';
import store from 'redux/store';
import { setLogout } from 'redux/slices/authSlice';
import AuthDetails from 'pages/auth/AuthDetails';
import { authentication } from 'firebaseconfig';
import { useDispatch } from 'react-redux';



// Please look service folder we are using in this instance there

const VerificationToast = () => (
  <div>
    <div className="toast-header">
      <p className="mr-auto">
        Your Are Not Verified <br />
        <b>
          <a href="/user-authdetails-update">
            Click Here To Complete Your Verification
          </a>
        </b>
      </p>
    </div>
  </div>
);

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_DEV_MODE === 'Live'
      ? process.env.REACT_APP_BASE_URL_LIVE
      : process.env.REACT_APP_BASE_URL_TEST
});

// runs on each request.
instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    config.headers = {
      Authorization: `Bearer ${token}`,
      deviceToken: window.localStorage.getItem('deviceToken')
    };

    return config;
  },
  (error) => {
    //console.log(error, 'fromapi');
    errorValidator(error.response.data);
    Promise.reject(error);
  }
);

let alreadyShownError = false;

instance.interceptors.response.use(
  (response) => {
    if (response.config.parse) {
      //perform the manipulation here and change the response object
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (store.getState().auth.authorized) {
        if (
          window.location.pathname !== '/login' &&
          window.location.pathname !== '/user-authdetails-update'
        ) {
          if (!alreadyShownError) {
            toast.warning(VerificationToast);
            alreadyShownError = true;
          }

          window.location.href = '/user-authdetails-update';
          
        }
        if (alreadyShownError) {
          setTimeout(() => {
            alreadyShownError = false;
          }, 3000);
        }
      } else {
        store.dispatch(setLogout());
      }
      // window.location.href = '/user-authdetails-update';
    }

    errorValidator(error.response.data);
    return Promise.reject(error);
  }
);

export default instance;
