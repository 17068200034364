// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { authentication } from 'firebaseconfig';

// import { notification } from 'antd';
// import history from 'utils/history';
// TODO -> Handle api errors

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    token: null,
    userLoading: true,
    authorized: false
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.authorized = true;
      window.localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setToken: (state, action) => {
      window.localStorage.setItem('token', action.payload);
      state.token = action.payload;
    },

    setLogout: async (state) => {
      await authentication.signOut();
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('user');
      state.user = {};
      state.authorized = false;
      state.token = null;

      // localStorage.removeItem('wishlistItems')
      notification.warn({
        message: 'Logout',
        description: 'User Logout Successful'
      });
      //console.log('Logoutttt');

      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    },

    // Loading handler
    setUserLoading: (state, action) => {
      state.userLoading = action.payload;
    }
  }
});

export const { setUser, setToken, setLogout, setUserLoading } =
  authSlice.actions;

// export const streamSelector = (state) => state.stream;

export default authSlice.reducer;
