import instance from 'api'

const productTemplateService = {}

productTemplateService.getProductsTemplate = async function (
  paginationQuery,
  filterQuery
) {
  try {
    const { data } = await instance.get(
      // `/api/v1/products/public?default=true&${paginationQuery}${filterQuery}`
      `/api/v1/products/public?${paginationQuery}${filterQuery}`
    )
    // const availableData = data.filter((cur) => cur.status !== 'Deleted')
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

productTemplateService.getProductTemplateById = async function (id) {
  try {
    const {
      data: { data },
    } = await instance.get(`/api/v1/producttemplates/${id}/public`)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}
productTemplateService.getProductTemplateBySlug = async function (slug) {
  try {
    const {
      data: { data },
    } = await instance.get(`/api/v1/producttemplates/slug/${slug}`)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}
productTemplateService.getProductTemplateSubs = async function (id) {
  try {
    const {
      data: { data },
    } = await instance.get(`/api/v1/producttemplates/substitutes/${id}`)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

export default productTemplateService
