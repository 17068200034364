/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getProductsTemplateBySearch,
  setFilters
} from 'redux/slices/productTemplateSlice';
import SearchSuggestion from './SearchSuggestion';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSuggestionShown, setIsSuggestionShown] = useState(false);

  // const [categories, setCategories] = useState([])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { filters, searchSuggestions } = useSelector(
    (state) => state.productTemplate
  );

  // useEffect(() => {
  //   // All category
  //   fetch(
  //     `${process.env.REACT_APP_BASE_URL}/api/catalog/v1/category?status=active`
  //   )
  //     .then((resp) => resp.json())
  //     .then(({ data }) => {
  //       const cat = data.filter((cur) => cur.priorityOrder === 1)
  //       // if (cat.length <= 9) {
  //       setCategories(cat)
  //     })
  // }, [])

  const handleSearch = (text) => {
    // setSearchTerm(text);
    setIsSuggestionShown(false);

    // const addSearchIfNotThereOrUpdateIfItsThere = (arr, newObj) => [
    //   ...arr.filter((o) => o.filterName !== newObj.filterName),
    //   { ...newObj },
    // ]

    // const res = addSearchIfNotThereOrUpdateIfItsThere(filters, {
    //   filterName: 'search',
    //   value: text || searchTerm,
    // })

    dispatch(
      setFilters([
        { filterName: 'search', value: searchTerm },
        { filterName: 'selected', value: 'search' }
      ])
    );

    navigate(`/products?search=${searchTerm}&selected=search`);

    // router.push({
    //     pathname: "/products",
    //     query: {
    //         search: searchTerm,
    //     },
    // });
    // setSearchTerm('')
  };

  const onSelect = (productTemplateSlug, productId, productType, category) => {
    if (productType === 'Medicine') {
      navigate(`/${category?.replaceAll(/\s+/g, '-')}/${productTemplateSlug}/${productId}/medicine`);
    } else {
      navigate(`/${category?.replaceAll(/\s+/g, '-')}/${productTemplateSlug}/${productId}`);
    }
  };

  const handleInput = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setIsSuggestionShown(true);

    dispatch(getProductsTemplateBySearch(`&search=${e.target.value}`));
  };

  // const onSearchSelect = (text) => {}
  return (
    <>
      <form>
        {/* <select
          className="select-active"
          onChange={(e) => navigate(`/products/category/${e.target.value}`)}
        >
          <option>All Categories</option>
          {categories.map((cat) => (
            <option
              key={cat._id}
    
              value={cat?._id}
            >
              {cat.name}
            </option>
          ))}
        </select> */}
        <input
          value={searchTerm}
          onKeyDown={handleInput}
          onChange={onSearchChange}
          // onFocus={(e) => {
          //   setIsSuggestionShown(true)
          //   setSearchTerm(e.target.value)
          // }}
          type="text"
          placeholder="Search for Medicines & Wellness Products"
        />
        <OutsideClickHandler onOutsideClick={() => setIsSuggestionShown(false)}>
          <SearchSuggestion
            searchSuggestions={searchSuggestions}
            onSelect={onSelect}
            searchTerm={searchTerm}
            setIsSuggestionShown={setIsSuggestionShown}
            isSuggestionShown={isSuggestionShown}
          />
        </OutsideClickHandler>
      </form>
    </>
  );
};

export default Search;
