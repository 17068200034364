// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit'
import brandService from 'service/brand'

// import { notification } from 'antd';
// import history from 'utils/history';
// TODO -> Handle api errors

export const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    brands: [],
    brand: {},
    loading: false,
  },
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload
    },
    setBrand: (state, action) => {
      state.brand = action.payload
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setBrands, setBrand, setLoading } = brandSlice.actions

export const getBrands = () => async (dispatch) => {
  dispatch(setLoading(true))
  const data = await brandService.getBrands()
  if (data) {
    dispatch(setBrands(data))
  }
  dispatch(setLoading(false))
}

export const getBrand = (id) => async (dispatch) => {
  dispatch(setLoading(true))
  const data = await brandService.getBrandById(id)
  if (data) {
    dispatch(setBrand(data))
  }
  dispatch(setLoading(false))
}

export default brandSlice.reducer
