/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { setFilters } from 'redux/slices/productTemplateSlice';

const Breadcrumb = ({
  parent,
  sub,
  subChild,
  subSubChild,
  noBreadcrumb,
  subLink,
  isSubFilterReset,
  subChildLink,
  subChildLinkFilter,
  subChildLinkFilterId
}) => {
  const dispatch = useDispatch();

  const setFilter = (val) => {
    dispatch(setFilters(val));
  };
  return (
    <>
      <div
        className={`page-header breadcrumb-wrap hide-in-mobile ${noBreadcrumb}`}
        style={{
          pointerEvents: window.location.pathname.includes(
            '/user-authdetails-update'
          )
            ? 'none'
            : 'all',
          borderBottom: '1px solid #ececec'
        }}
      >
        {/* <div className="container show-in-mobile">
          <div className="breadcrumbs">
            <Link to="/">{parent}</Link>
            {sub && (
              <>
                <span></span>
                {subLink ? (
                  <Link
                    to={subLink}
                    onClick={() => isSubFilterReset && setFilter([])}
                  >
                    {sub}
                  </Link>
                ) : (
                  sub
                )}
              </>
            )}
            {subChild && (
              <>
                <span></span>
                {subChildLink ? (
                  <Link
                    to={subChildLink}
                    onClick={() =>
                      subChildLinkFilter &&
                      setFilter([
                        {
                          filterName: 'categoryId',
                          value: subChildLinkFilterId,
                        },
                      ])
                    }
                  >
                    {subChild}
                  </Link>
                ) : (
                  subChild
                )}
              </>
            )}
            {subSubChild && (
              <>
                <span></span> {subSubChild}
              </>
            )}
          </div>
        </div> */}
        <div className="container hide-in-mobile">
          <div className="breadcrumb">
            <Link to="/">{parent}</Link>
            {sub && (
              <>
                <span></span>
                {subLink ? (
                  <Link
                    to={subLink}
                    onClick={() => isSubFilterReset && setFilter([])}
                  >
                    {sub}
                  </Link>
                ) : (
                  sub
                )}
              </>
            )}
            {subChild && (
              <>
                <span></span>
                {subChildLink ? (
                  <Link
                    to={subChildLink}
                    onClick={() =>
                      subChildLinkFilter &&
                      setFilter([
                        {
                          filterName: 'categoryId',
                          value: subChildLinkFilterId
                        }
                      ])
                    }
                  >
                    {subChild}
                  </Link>
                ) : (
                  subChild
                )}
              </>
            )}
            {subSubChild && (
              <>
                <span></span> {subSubChild}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
