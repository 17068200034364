// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit';
import informationService from 'service/information';

// import { notification } from 'antd';
// import history from 'utils/history';
// TODO -> Handle api errors

export const informationSlice = createSlice({
  name: 'information',
  initialState: {
    informations: [],
    information: {},
    loading: false,
    isFetched: false
  },
  reducers: {
    setInformations: (state, action) => {
      state.informations = action.payload;
    },
    setInformation: (state, action) => {
      state.information = action.payload;
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsFetched: (state, action) => {
      state.isFetched = action.payload;
    }
  }
});

export const { setInformations, setInformation, setLoading, setIsFetched } =
  informationSlice.actions;

export const getInformations = () => async (dispatch) => {
  dispatch(setLoading(true));
  const data = await informationService.getInformations();
  if (data) {
    dispatch(setInformations(data));
    dispatch(setIsFetched(true));
  }
  dispatch(setLoading(false));
};

export const getInformation = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  const data = await informationService.getInformationById(id);
  if (data) {
    dispatch(setInformation(data));
  }
  dispatch(setLoading(false));
};

export default informationSlice.reducer;
