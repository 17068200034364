import instance from 'api'
const brandService = {}

brandService.getBrands = async function () {
  try {
    const {
      data: { data },
    } = await instance.get('/api/v1/brands/public')
    const resData = data.filter((cur) => cur.status === 'Active')
    return resData
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

brandService.getBrandById = async function (id) {
  try {
    const {
      data: { data },
    } = await instance.get(`/api/v1/brands/${id}/public`)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

export default brandService
