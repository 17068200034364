import { configureStore, applyMiddleware } from '@reduxjs/toolkit'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

// const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

// export default configureStore({
//   composedEnhancer,
//   reducer: {
//     //   Add reducers here
//   },
// })
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import authSlice from './slices/authSlice'
import categorySlice from './slices/categorySlice'
import productSlice from './slices/productSlice'
import cartSlice from './slices/cartSlice'
import homeWidgetSlice from './slices/homeWidgetSlice'
import productTemplateSlice from './slices/productTemplateSlice'
import informationSlice from './slices/informationSlice'
import wishlistSlice from './slices/wishlistSlice'
import orderSlice from './slices/orderSlice'
import brandSlice from './slices/brandSlice'
import manufactureSlice from './slices/manufactureSlice'
import settingSlice from './slices/settingSlice'

export const history = createBrowserHistory()

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

export default configureStore({
  composedEnhancer,
  reducer: {
    router: connectRouter(history),
    auth: authSlice,
    category: categorySlice,
    brand: brandSlice,
    manufacture: manufactureSlice,
    product: productSlice,
    cart: cartSlice,
    wishlist: wishlistSlice,
    homeWidget: homeWidgetSlice,
    productTemplate: productTemplateSlice,
    information: informationSlice,
    order: orderSlice,
    setting: settingSlice,
    //   Add reducers here
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
})
