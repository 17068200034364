// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

//
// const firebaseConfig = {
//   apiKey: "AIzaSyBUdVQKSYiMrWXKZLhpcR4pmtIUFa-40w0",
//   authDomain: "zapkartnew2022-dc8a8.firebaseapp.com",
//   projectId: "zapkartnew2022-dc8a8",
//   storageBucket: "zapkartnew2022-dc8a8.appspot.com",
//   messagingSenderId: "812753080859",
//   appId: "1:812753080859:web:63d1a2c52ff2a2a5161ebe",
// };

// // Initialize Firebase
// const intializeFirebase = initializeApp(firebaseConfig);

// export const authentication = getAuth(intializeFirebase);

// Your web app's Firebase configuration
let firebaseConfig = {};

// if (process.env.REACT_APP_DEV_MODE === 'Live') {
//   firebaseConfig = {
//     apiKey: 'AIzaSyDVRszGmdIg-Me-79_x9yuntOdt6Mz2LdM',
//     authDomain: 'zapkartlive.firebaseapp.com',
//     projectId: 'zapkartlive',
//     storageBucket: 'zapkartlive.appspot.com',
//     messagingSenderId: '1010821971171',
//     appId: '1:1010821971171:web:dcd6a429a1f65cb3ead9d6',
//   }
// } else {
//   firebaseConfig = {
//     apiKey: 'AIzaSyDVRszGmdIg-Me-79_x9yuntOdt6Mz2LdM',
//     authDomain: 'zapkartlive.firebaseapp.com',
//     projectId: 'zapkartlive',
//     storageBucket: 'zapkartlive.appspot.com',
//     messagingSenderId: '1010821971171',
//     appId: '1:1010821971171:web:dcd6a429a1f65cb3ead9d6',
//   }
// }

if (process.env.REACT_APP_DEV_MODE === 'Live') {
  firebaseConfig = {
    apiKey: 'AIzaSyDVRszGmdIg-Me-79_x9yuntOdt6Mz2LdM',
    authDomain: 'zapkartlive.firebaseapp.com',
    projectId: 'zapkartlive',
    storageBucket: 'zapkartlive.appspot.com',
    messagingSenderId: '1010821971171',
    appId: '1:1010821971171:web:dcd6a429a1f65cb3ead9d6',
    measurementId: "G-FBWMFCCWG7"
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyDmZpETpKWwUkYbDJdmq9XKmpK3iTcs6Dc',
    authDomain: 'ecommerce-test-e6c33.firebaseapp.com',
    projectId: 'ecommerce-test-e6c33',
    storageBucket: 'ecommerce-test-e6c33.appspot.com',
    messagingSenderId: '2805389390',
    appId: '1:2805389390:web:31c1bd337020b2368f0b1f'
  };
  // firebaseConfig = {
  //   apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
  //   authDomain: 'ecommerce-74b71.firebaseapp.com',
  //   projectId: 'ecommerce-74b71',
  //   storageBucket: 'ecommerce-74b71.appspot.com',
  //   messagingSenderId: '1031062435017',
  //   appId: '1:1031062435017:web:d6b5ac03cd40d344480918'
  // };
}

//console.log('firebaseConfig', firebaseConfig);

// Initialize Firebase
const intializeFirebase = initializeApp(firebaseConfig);

export const authentication = getAuth(intializeFirebase);
export const auth = getAuth(intializeFirebase);

const messaging = getMessaging();

const vapidKey =
  process.env.REACT_APP_DEV_MODE === 'Live'
    ? process.env.REACT_APP_FIREBASE_VPA_KEY_LIVE
    : process.env.REACT_APP_FIREBASE_VPA_KEY_TEST;

export const getDeviceToken = (setDeviceToken) => {
  getToken(messaging, { vapidKey })
    .then((currentToken) => {
      if (currentToken) {
        setDeviceToken(currentToken);
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        //console.log(
        // 'No registration token available. Request permission to generate one.'
        // );
        // ...
      }
    })
    .catch((err) => {
      setDeviceToken(null);
      //console.log('An error occurred while retrieving token. ', err);
      // ...
    });
};

export default intializeFirebase;

