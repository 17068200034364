import instance from 'api'

const productService = {}

productService.getProducts = async function (queryString) {
  try {
    const {
      data: { data },
      // } = await instance.get(`/api/v1/products/public?default=true&${queryString}`)
    } = await instance.get(`/api/v1/products/public?${queryString}`)
    const availableData = data.filter((cur) => cur.status !== 'Deleted')
    return availableData
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

productService.getProductById = async function (id) {
  try {
    const {
      data: { data },
    } = await instance.get(`/api/v1/products/${id}/public`)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

export default productService
