import React from 'react';
import 'react-input-range/lib/css/index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'react-responsive-modal/styles.css';
// Swiper Slider
import 'swiper/css';
import 'swiper/css/navigation';

import 'antd/dist/antd.css';

import './assets/css/main.css';

import { ToastContainer } from 'react-toastify';
import Router from './navigation/routes';
import BeforeAppLoad from 'components/layout/BeforeAppLoad';

const App = () => (
  <BeforeAppLoad>
    <Router />
    <ToastContainer autoClose={2000} />
  </BeforeAppLoad>
);

export default App;
// https://zapkart-firebase.netlify.app
