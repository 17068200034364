import instance from 'api'

const cartService = {}

cartService.getCart = async function () {
  try {
    const {
      data: {
        data: { items },
      },
    } = await instance.get('/api/v1/cart')
    return items
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

cartService.addCartItem = async function (sendingData) {
  try {
    const { data } = await instance.post('/api/v1/cart/item', sendingData)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

cartService.updateCartQty = async function (productId, sendingData) {
  try {
    const { data } = await instance.put(
      `/api/v1/cart/item/${productId}/quantity`,
      sendingData
    )
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

cartService.deleteCartItem = async function (productId) {
  try {
    const { data } = await instance.delete(`/api/v1/cart/item/${productId}`)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

export default cartService
