/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import Layout from "components/layout/Layout";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import ErrorText from "components/common/ErrorText";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setToken, setUser } from "../../redux/slices/authSlice";
import scrollToTop from "util/scrollToTop";
import {
  linkWithPhoneNumber,
  RecaptchaVerifier,
  sendEmailVerification,
  updateEmail,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import { authentication } from "firebaseconfig";
import { useRef } from "react";
import { getUserProfileApi, createUserApi, updateUserApi } from "api/user";
import ImageUploader from "components/common/ImageUpload";
import { singleImageUploader } from "util/imageUploader";
import { Button, Modal } from "react-bootstrap";
import getFBError from "util/firebaseErrors";
import authService from "service/auth";
import Preloader from "components/common/Preloader";
// import { scrollToTop } from '../../utils/scroll'

const AuthDetails = () => {
  //console.log("entered AuthDetails component");
  const [phoneVerificationOtp, setPhoneVerificationOtp] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  // const [isVerified, setIsVerified] = useState(false)
  const [verifyDisable, setVerifyDisable] = useState(false)

  const [showPhoneOtpModal, setShowPhoneOtpModal] = useState(false);

  const captchaCont = useRef(null);

  const create = () => {

  }

  useEffect(() => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier = null;
    }
  }, []);

  // Phonenumber

  // const [phoneVerified, setPhoneVerified] = useState(false)

  const [currentUserFormData, setCurrentUserFormData] = useState({});
  const [userAlreadyExistInDB, setUserAlreadyExistInDB] = useState(false);

  // Check if user is signed with email and password
  const [isPasswordRegister, setIsPasswordRegister] = useState(false);

  // Email

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   scrollToTop()
  //   if (authorized) {
  //     navigate('/')
  //   }
  // }, [authorized, navigate])

  // useEffect(() => {
  //   setIsVerified(
  //     user.emailVerified && user.phoneVerified && user.passwordVerified
  //       ? true
  //       : false
  //   )
  // }, [user])

  useEffect(() => {
    const getProfile = async () => {
      // //console.log(newToken, 'the-new-token')
      setInitialLoading(true);

      // await dispatch(setToken(newToken))
      try {
        const {
          data: { data },
        } = await getUserProfileApi();

        setCurrentUserFormData({
          email: data.email || "",
          phone: data?.phone?.slice(3) || "",
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          displayImage: data.displayImage,
        });
        setUserAlreadyExistInDB(true);

        if (
          user.emailVerified &&
          user.phoneVerified &&
          data.firstName &&
          data.lastName
        ) {
          // toast.success('User Fully Authenticated')
          navigate("/");
        }
      } catch (err) {
        if (err.response.data.status === 401) {
          setCurrentUserFormData({
            email: user.email || "",
            phone: user?.phone?.slice(3) || "",
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            displayImage: user.displayImage,
          });
          setUserAlreadyExistInDB(false);
        } else {
          toast.error(err.response.data.message);
        }
      }

      setInitialLoading(false);
    };

    getProfile();

    const passwordAvailable = authentication.currentUser.providerData?.find(
      (cur) => cur.providerId === "password"
    );
    setIsPasswordRegister(
      passwordAvailable?.providerId === "password" ? true : false
    );
  }, [user]);

  // Email

  const validationSchema = Yup.object({
    email: Yup.string().email("*Invalid Email").required("* Email Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
  });

  // -------------------------------------------------------
  const updateUserEmail = async (email) => {
    //console.log()
    const user = authentication.currentUser;

    try {
      // await updateEmail(user, email);
      await verifyBeforeUpdateEmail(user, email);
      toast.success("Email Linked Success");
    } catch (err) {
      //console.log("error updateUserEmail", err)
      // toast.error(getFBError(err.code)); //hided bcoz getFBError has wrong msg for this error
      toast.error(err.message);
      return "error";
    }
  };

  const emailVerification = async (email) => {
    //Email Verification
    try {
      sendEmailVerification(authentication.currentUser).then(() => {
        toast.success(
          `Email is sent to ${email}. Click the link to complete your Verification.`,
          {
            autoClose: false,
          }
        );
        // Email verification sent!
      });
    } catch (err) {
      toast.error(getFBError(err.code));

    }
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    const { password } = values;
    const user = authentication.currentUser;
    const email = user.email;
    const emailVerified = user.emailVerified;
    const phoneNumberVerified = user.phoneNumber;

    if (phoneNumberVerified) {
      // if (emailExist) {
      //   values.email = emailExist
      // }

      if (!email) {
        const res = await updateUserEmail(values.email);
        if (res === "error") {
          return;
        }
      }
      if (!emailVerified) {
        const sendingEmail = email || values.email;
        await emailVerification(sendingEmail);
      }

      const imgValue = await singleImageUploader(
        values.displayImage,
        "profile"
      );
      values.displayImage = imgValue;

      const sendingValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        displayImage: values?.displayImage,
      };

      if (!userAlreadyExistInDB) {
        try {
          // Refreshing token
          const idTokenResultCreate = await user.getIdTokenResult(true);
          await dispatch(setToken(idTokenResultCreate.token));

          await createUserApi(sendingValues);

          // Refreshing token
          const idTokenResultGetProfile = await user.getIdTokenResult(true);
          await dispatch(setToken(idTokenResultGetProfile.token));

          const { data } = await authService.getUserProfile();

          const dispatchingData = {
            firstName: data.firstName,
            id: data?.id,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            address: data.address,
            prescriptions: data.prescriptions,
            displayImage: data.displayImage,
            emailVerified: emailVerified,
            phoneVerified: user.phoneNumber ? true : false,
          };

          dispatch(setUser(dispatchingData));

          navigate("/");
        } catch (err) {
          toast.error(err.response.data.message);
        }
      } else {
        try {
          await updateUserApi(sendingValues);
          const { data } = await authService.getUserProfile();

          const dispatchingData = {
            firstName: data.firstName,
            id: data.id,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            address: data.address,
            prescriptions: data.prescriptions,
            displayImage: data.displayImage,
            emailVerified: emailVerified ? true : false,
            phoneVerified: user.phoneNumber ? true : false,
          };
          dispatch(setUser(dispatchingData));

          navigate("/");
        } catch (err) {
          toast.error(err.response.data.message);
        }
      }
    } else {
      toast.error("You need To Verify your phone Number");
    }
  };

  // Link phonenumber
  const linkPhoneNumber = (phoneNumber) => {
    if (!window.recaptchaVerifier) {
      //console.log("!window.recaptchaVerifier", !window.recaptchaVerifier)
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          siteKey: process.env.REACT_APP_DEV_MODE === 'Live' ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE
            : process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST,
        },
        authentication
      );
    }
    //console.log("window.recaptchaVerifier yes", window.recaptchaVerifier)
    window.recaptchaVerifier.render();

    linkWithPhoneNumber(
      authentication.currentUser,
      `+91 ${phoneNumber}`,
      window.recaptchaVerifier
    )
      .then(function (confirmationResult) {
        toast.success("OTP sent to your phone number");
        setShowPhoneOtpModal(true);
        setVerifyDisable(false)
        // Ask user to provide the SMS code.
        // var code = window.prompt('Enter your OTP here')
        // return confirmationResult.confirm(code)

        //console.log("window.confirmationResult", window.confirmationResult)
        //console.log("confirmationResult", confirmationResult)
        window.confirmationResult = confirmationResult;
      })

      // .then((result) => {
      //   toast.success('Phone Number Linked Successfully')
      //   setPhoneVerified(true)
      // })
      .catch((err) => {
        //console.log("error", err);
        // setPhoneVerified(false)
        setVerifyDisable(false);
        setShowPhoneOtpModal(false);
        toast.error(getFBError(err.code));
        //reset the reCAPTCHA so the user can try again
        window.recaptchaVerifier = null;
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            siteKey: process.env.REACT_APP_DEV_MODE === 'Live' ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE
              : process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST,
          },
          authentication
        );
      });
  };

  const otpSubmit = async () => {
    try {
      const res = await window.confirmationResult.confirm(phoneVerificationOtp);
      toast.success("Phone Number Linked Successfully");
      dispatch(setUser({ ...user, phoneVerified: true }));
      // setPhoneVerified(true)
      setShowPhoneOtpModal(false);
    } catch (err) {
      // setPhoneVerified(false)
      //console.log(getFBError(err.code), "err");
      setShowPhoneOtpModal(false);
      toast.error(getFBError(err.code));
    }
  };

  return (
    <>
      {initialLoading ? (
        <Preloader />
      ) : (
        <Layout parent="Home" sub="Pages" subChild="Login & Register">
          <div className="page-content pt-50 pb-50">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                  <div className="row">
                    <div className="col-lg-6 pr-30 d-none d-lg-block">
                      <img
                        className="border-radius-15"
                        src={require("assets/imgs/page/login-1.png")}
                        alt=""
                      />
                    </div>
                    <div className="col-lg-6 col-md-8">
                      <div className="login_wrap widget-taber-content background-white">
                        <div className="padding_eight_all bg-white">
                          <div className="heading_s1">
                            <h3 style={{ marginBottom: "30px" }}>Personal Details</h3>
                          </div>

                          <Formik
                            initialValues={currentUserFormData}
                            onSubmit={onSubmitHandler}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                          >
                            {(fomrik) => {
                              const {
                                touched,
                                errors,
                                isSubmitting,
                                values,
                                setFieldValue,
                              } = fomrik;

                              return (
                                <Form>
                                  {/* <h4>Register with OTP</h4> */}
                                  <div className="form-group">
                                    <label
                                      className="user-details-label"
                                      htmlFor="firstName"
                                    >
                                      First Name
                                    </label>
                                    <Field
                                      className="first-name-signup form-control"
                                      type="text"
                                      name="firstName"
                                      style={{
                                        border: `${touched.firstName && errors.firstName
                                          ? "1px solid red"
                                          : ""
                                          }`,
                                      }}
                                    />
                                    <ErrorMessage
                                      name="firstName"
                                      component={ErrorText}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label
                                      htmlFor="lastName"
                                      className="user-details-label"
                                    >
                                      Last Name
                                    </label>
                                    <Field
                                      type="text"
                                      name="lastName"
                                      className="form-control"
                                      style={{
                                        border: `${touched.lastName && errors.lastName
                                          ? "1px solid red"
                                          : ""
                                          }`,
                                      }}
                                    />
                                    <ErrorMessage
                                      name="lastName"
                                      component={ErrorText}
                                    />
                                  </div>
                                  {/* <div className="form-group">
                                      <label htmlFor="displayImage">
                                        Display Image
                                      </label>
                                      <ImageUploader
                                        source={
                                          values.displayImage
                                            ? values.displayImage
                                            : null
                                        }
                                        name="displayImage"
                                        setSource={setFieldValue}
                                      />
    
                                      <ErrorMessage
                                        name="displayImage"
                                        component={ErrorText}
                                      />
                                    </div> */}
                                  {/* OTP => PHONE VERIFICATION */}
                                  {/* {!user.phoneVerified && !phoneVerified ? ( */}
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        className="form-group"
                                        style={{ width: "70%" }}
                                      >
                                        <label
                                          htmlFor="lastName"
                                          className="user-details-label"
                                        >
                                          Phone
                                        </label>
                                        <Field
                                          type="phone"
                                          name="phone"
                                          value={values.phone}
                                          className="form-control personal-phone"
                                          disabled={user.phoneVerified}
                                          placeholder="Please Enter your phone Number"
                                          // onChange={(e) => {
                                          //   // setPhoneNumber(e.target.value)
                                          //   values.phone = e.target.value
                                          // }}
                                          style={{
                                            border: `${touched.phone && errors.phone
                                              ? "1px solid red"
                                              : ""
                                              }`,
                                          }}
                                        />
                                        <ErrorMessage
                                          name="phone"
                                          component={ErrorText}
                                        />
                                      </div>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault()
                                          setVerifyDisable(true)
                                          if (values.phone?.length === 10) {
                                            linkPhoneNumber(values.phone);
                                          } else {
                                            toast.error(
                                              "Please Enter Valid Phone Number"
                                            );
                                            setVerifyDisable(false)
                                          }
                                        }}
                                        className="btn mt-5 "
                                        style={{
                                          width: "30%",
                                          padding: 0,
                                          height: "45px",
                                          marginBottom: "-3px",
                                        }}
                                        disabled={user.phoneVerified || verifyDisable}
                                      >
                                        Verify
                                      </button>
                                    </div>
                                    {/* {!user.phoneVerified && (
                                      // <p
                                      //   onClick={() => {
                                      //     if (values.phone?.length === 10) {
                                      //       linkPhoneNumber(values.phone)
                                      //     } else {
                                      //       toast.error(
                                      //         'Please Enter Valid Phone Number'
                                      //       )
                                      //     }
                                      //   }}
                                      //   style={{
                                      //     textDecoration: 'underline',
                                      //     cursor: 'pointer',
                                      //     marginBottom: '10px',
                                      //   }}
                                      // >
                                      //   Verify PhoneNumber
                                      // </p>

                                      <a
                                        href="#/"
                                        className="link unselect-text"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                          if (values.phone?.length === 10) {
                                            linkPhoneNumber(values.phone);
                                          } else {
                                            toast.error(
                                              "Please Enter Valid Phone Number"
                                            );
                                          }
                                        }}
                                      >
                                        Verify PhoneNumber
                                      </a>
                                    )} */}
                                  </>
                                  <div className="form-group mt-10">
                                    <label
                                      htmlFor="lastName"
                                      className="user-details-label"
                                    >
                                      Email
                                    </label>
                                    <Field
                                      type="email"
                                      name="email"
                                      className="form-control"
                                      disabled={
                                        isPasswordRegister || user.emailVerified
                                      }
                                      style={{
                                        border: `${touched.email && errors.email
                                          ? "1px solid red"
                                          : ""
                                          }`,
                                      }}
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component={ErrorText}
                                    />
                                  </div>

                                  <div>
                                    <button
                                      style={{ cursor: "pointer" }}
                                      className="btn w-100 mb-3"
                                      disabled={!user.phoneVerified}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>

                          <Modal
                            show={showPhoneOtpModal}
                            className="personal-modal"
                          // onHide={() => setShowPhoneOtpModal(false)}
                          // back
                          >
                            <Modal.Header className="d-flex justify-content-between">
                              <Modal.Title>Verify OTP</Modal.Title>
                              <span
                                style={{ fontSize: 20 }}
                                className="fi-rs-cross-circle"
                                role="button"
                                onClick={() => setShowPhoneOtpModal(false)}
                              ></span>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="otp"
                                  placeholder="Enter OTP"
                                  className="form-control"
                                  onChange={(e) =>
                                    setPhoneVerificationOtp(e.target.value)
                                  }
                                />
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="primary"
                                onClick={() => otpSubmit()}
                              >
                                Verify
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="recaptcha-container" ref={captchaCont}></div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default AuthDetails;
