import instance from 'api'
const categoryService = {}

categoryService.getCategories = async function (filter = '') {
  try {
    const {
      data: { data },
    } = await instance.get(`/api/v1/categories/public?${filter}`)
    
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

categoryService.getCategoryById = async function (id) {
  try {
    const {
      data: { data },
    } = await instance.get(`/api/v1/categories/${id}/public`)
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

export default categoryService
