/* eslint-disable react/prop-types */
import React, { Suspense } from 'react';

import {
  // unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Preloader from '../components/common/Preloader';
// import { history } from 'redux/store';

function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
}

const loadable = (loader) => React.lazy(() => componentLoader(loader, 5));

const routes = [
  // Home
  {
    path: '/',
    component: loadable(() => import('../pages/Home')),
    exact: true,
    authorize: false
  },
  {
    path: '/categories',
    component: loadable(() => import('../pages/Categories')),
    exact: true,
    authorize: false
  },
  {
    path: '/brands',
    component: loadable(() => import('../pages/Brands')),
    exact: true,
    authorize: false
  },

  // Contact
  {
    path: '/contact',
    component: loadable(() => import('../pages/Contact')),
    exact: true,
    authorize: false
  },

  // About
  // {
  //   path: '/about',
  //   component: loadable(() => import('../pages/About')),
  //   exact: true,
  //   authorize: false,
  // },

  // Wishlist
  {
    path: '/wishlist',
    component: loadable(() => import('../pages/Wishlist')),
    exact: true,
    authorize: true
  },

  // Cart
  {
    path: '/cart',
    component: loadable(() => import('../pages/Cart')),
    exact: true,
    authorize: true
  },

  // Orders
  // {
  //   path: '/orders',
  //   component: loadable(() => import('../pages/Orders')),
  //   exact: true,
  //   authorize: false,
  // },

  // Checkout
  {
    path: '/checkout',
    component: loadable(() => import('../pages/Checkout')),
    exact: true,
    authorize: false
  },

  // Account
  {
    path: '/account/*',
    component: loadable(() => import('../pages/Account')),
    exact: true,
    authorize: true
  },
  // Products
  // {
  //   path: '/products/category/:id',
  //   component: loadable(() => import('../pages/Products')),
  //   exact: true,
  //   authorize: false,
  // },

  {
    path: '/medicines',
    component: loadable(() => import('../pages/MedicineProducts')),
    exact: true,
    authorize: false
  },
  {
    path: '/customer',
    component: loadable(() => import('../pages/forgot-password')),
    exact: true,
    authorize: false
  },

  // {
  //   path: '/products/search',
  //   component: loadable(() => import('../pages/Products')),
  //   exact: true,
  //   authorize: false,
  // },

  // 404
  {
    path: '/404',
    component: loadable(() => import('../pages/404')),
    exact: true,
    authorize: false
  },
  //401 testing
  {
    path: '/401',
    component: loadable(() => import('../pages/test-401')),
    exact: true,
    authorize: false
  },

  // Auth
  {
    path: '/login',
    component: loadable(() => import('../pages/auth/Login')),
    exact: true,
    authorize: false
  },
  {
    path: '/register',
    component: loadable(() => import('../pages/auth/Register/index')),
    exact: true,
    authorize: false
  },
  {
    path: '/forgot-password',
    component: loadable(() => import('../pages/auth/ForgotPassword')),
    exact: true,
    authorize: false
  },
  {
    path: '/register/complete',
    component: loadable(() =>
      import('../pages/auth/Register/RegisterComplete')
    ),
    exact: true,
    authorize: false
  },
  {
    path: '/user-authdetails-update',
    component: loadable(() => import('../pages/auth/AuthDetails')),
    exact: true,
    authorize: true
  },
  {
    path: '/information/:id',
    component: loadable(() => import('../pages/Information')),
    exact: true,
    authorize: false
  },

  {
    path: '/order-success/:orderId',
    component: loadable(() => import('../pages/OrderSuccess')),
    exact: true,
    authorize: true
  },

  {
    path: '/:categoryName',
    component: loadable(() => import('../pages/Products')),
    exact: true,
    authorize: false
  },
  {
    path: '/:categoryName/filter',
    component: loadable(() => import('../pages/Products')),
    exact: true,
    authorize: false
  },

  {
    path: '/:categoryName/:productTemplateSlug/:id',
    component: loadable(() => import('../pages/ProductDetails')),
    exact: true,
    authorize: false
  },

  {
    path: '/:categoryName/:productTemplateSlug/:id/medicine',
    component: loadable(() => import('../pages/MedicineProductDetails')),
    exact: true,
    authorize: false
  }
];

const Router = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/" exact>
                <Redirect to="/home" />
              </Route> */}
        {routes.map((route) => {
          const { component: Component } = route;
          if (route.authorize)
            return (
              <Route
                path={route.path}
                key={route.path}
                exact={route.exact}
                element={
                  <Suspense fallback={<Preloader />}>
                    <PrivateRoute component={route.component} {...route} />
                  </Suspense>
                }
              />
            );
          return (
            <Route
              path={route?.path}
              element={
                <Suspense fallback={<Preloader />}>
                  <Component />
                </Suspense>
              }
              key={route?.path}
              exact={route?.exact}
            />
          );
        })}
        <Route path="*" element={<Navigate to="/404" />} />

        {/* <Navigate from="*" to="/home" /> */}
      </Routes>

      {/* <Route component={NotFoundPage} /> */}
    </>
  );
};

export default Router;
