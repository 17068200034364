// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit'
import manufacturerService from 'service/manufacture'

// import { notification } from 'antd';
// import history from 'utils/history';
// TODO -> Handle api errors

export const manufactureSlice = createSlice({
  name: 'manufacture',
  initialState: {
    manufactures: [],
    manufacture: {},
    loading: false,
  },
  reducers: {
    setManufactures: (state, action) => {
      state.manufactures = action.payload
    },
    setManufacture: (state, action) => {
      state.manufacture = action.payload
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setManufactures, setManufacture, setLoading } =
  manufactureSlice.actions

export const getManufactures = () => async (dispatch) => {
  dispatch(setLoading(true))
  const data = await manufacturerService.getManufactures()
  if (data) {
    dispatch(setManufactures(data))
  }
  dispatch(setLoading(false))
}

export default manufactureSlice.reducer
