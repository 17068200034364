/* eslint-disable no-loop-func */
// Add Up Redux Slices Here
// create redux slice function as reducer

// Every Page has their own redux slice function
import { createSlice } from '@reduxjs/toolkit';
import homeWidgetService from 'service/homeWidget';
import productService from 'service/product';
import productTemplateService from 'service/productTemplateService';

export const productTemplateSlice = createSlice({
  name: 'productTemplate',
  initialState: {
    // This is entire product template data
    productsTemplate: [],
    // This is defaults products for show case
    defaultProducts: [],
    // Single selected current product template
    productTemplate: {},

    // This is another products from selected variants
    otherProductsFromVariant: [],

    // This is selected product from product template
    product: {},

    variant: {},

    filters: [],

    totalProductTemplateCount: 0,

    loading: false,

    searchSuggestions: []
  },
  reducers: {
    setProductsTemplate: (state, action) => {
      state.productsTemplate = action.payload;
    },
    setProductTemplate: (state, action) => {
      state.productTemplate = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setVariant: (state, action) => {
      state.variant = action.payload;
    },
    setSearchSuggestions: (state, action) => {
      state.searchSuggestions = action.payload;
    },

    setDefaultProducts: (state, action) => {
      state.defaultProducts = action.payload;
    },
    addDefaultProducts: (state, action) => {
      state.defaultProducts = state.defaultProducts.concat(action.payload);
    },

    setOtherProductsFromVariant: (state, action) => {
      state.otherProductsFromVariant = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setProductTemplateTotalCount: (state, action) => {
      state.totalProductTemplateCount = action.payload;
    },

    setResetProductsDetails: (state) => {
      state.product = {};
      state.otherProductsFromVariant = [];
      state.productTemplate = {};
    },

    // Loading handler
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const {
  setProductsTemplate,
  setProduct,
  setVariant,
  setDefaultProducts,
  addDefaultProducts,
  setOtherProductsFromVariant,
  setLoading,
  setFilters,
  setProductTemplate,
  setSearchSuggestions,
  setProductTemplateTotalCount,
  setResetProductsDetails
} = productTemplateSlice.actions;

export const getProductsTemplateBySearch =
  (query = '') =>
    async (dispatch) => {
      // const data = await productTemplateService.getProductsTemplate(
      //   query,
      //   searchQuery
      // )
      const data = await productService.getProducts(query);

      if (data) {
        const searchSuggestions = data.map((cur) => {
          console.log('current value', cur)
          return {
            text: cur?.variant?.name || cur?.name,
            image:
              cur?.variant?.images?.length > 0
                ? cur?.variant?.images[0]
                : cur?.images?.length > 0
                  // ? cur?.images[0]
                  ? cur?.thumbnails[0]
                  : cur?.medicineTypeImage?
                  cur?.medicineTypeImage
                  : null,
            category: cur?.category?.name ? cur?.category?.name : null,
            productTemplateId: cur?.productTemplateId,
            // composition: cur?.composition,
            productTemplateSlug: cur?.productTemplateSlug,
            productId:
              // cur?.products?.length > 0
              //   ? cur?.products?.find((cur) => cur?.default)?.id
              cur?.qty > 0 ? cur?.id : cur?.id,
            productType: cur?.productType
          };
        });

        //console.log(searchSuggestions, 'plhjkhgujk');
        dispatch(setSearchSuggestions(searchSuggestions));

        // if (paginationParams?.includes('page=1')) {
        //   dispatch(setSearchSuggestions(searchSuggestions))
        // } else {
        //   dispatch(
        //     setSearchSuggestions([
        //       ...getState().productTemplate.searchSuggestions,
        //       ...searchSuggestions,
        //     ])
        //   )
        // }
      }
    };

export const getProductsTemplate =
  (
    paginationQuery = '',
    filterQuery = '',
    widgetProductId,
    overwrite = true,
    setFilters
  ) =>
    async (dispatch) => {
      dispatch(setLoading(true));
      let data = widgetProductId
        ? await homeWidgetService.getHomeWidgetById(
          widgetProductId,
          paginationQuery,
          filterQuery
        )
        : await productTemplateService.getProductsTemplate(
          paginationQuery,
          filterQuery
        );

      //console.log(data, 'defaultProductdefaultProduct');

      if (data.data.listingItems) {
        data = {
          total: data.data.total,
          data: data.data.listingItems,
          brands: data.brands,
          categories: data.categories,
          manufacturers: data.manufacturers,
          from: 'widget'
        };
      }

      if (data) {
        //console.log(data, 'ljjl');
        //   for (let i = 0; i < data.length; i++) {
        //     // if (data[i].listingType === 'ProductTemplates') {
        // const defaultProduct = []
        //console.log(data.total, 'pslkwl');
        dispatch(setProductTemplateTotalCount(data.total));

        if (data?.from === 'widget') {
          const defaultProduct = [];
          data.data.forEach((item) => {
            if (item.products.length > 0) {
              item.products.forEach((prod) => {
                prod.default &&
                  defaultProduct.push({
                    ...item,
                    ...prod,
                    productTemplateSlug: item?.slug,
                    images:
                      prod?.variant?.images?.length > 0
                        ? prod?.variant?.images
                        : prod?.images?.length > 0
                          ? prod?.images
                          : item?.images
                  });
              });
            } else {
              defaultProduct.push(item);
            }
          });

          dispatch(setProductsTemplate(data?.data));

          overwrite
            ? dispatch(setDefaultProducts(defaultProduct))
            : dispatch(addDefaultProducts(defaultProduct));
          setFilters &&
            setFilters(data.brands, data.manufacturers, data.categories);
        } else {
          dispatch(setProductsTemplate(data?.data));
          overwrite
            ? dispatch(setDefaultProducts(data?.data))
            : dispatch(addDefaultProducts(data?.data));
          setFilters &&
            setFilters(data.brands, data.manufacturers, data.categories);
        }

        // dispatch(setDefaultProducts(defaultProduct))
      }
      dispatch(setLoading(false));
    };

export const getProductfromProductTemplate =
  (productTemplateSlug, productId) => async (dispatch) => {
    dispatch(setLoading(true));
    // const data = await productTemplateService.getProductTemplateById(
    //   productTemplateSlug
    // )

    const data = await productTemplateService.getProductTemplateBySlug(
      productTemplateSlug
    );
    //console.log('show-mwrr', data);

    if (data) {
      const productTemplate = data;
      dispatch(setProductTemplate(productTemplate));

      if (productId) {
        const product = productTemplate.products.find(
          (item) => item.id === productId
        );
        //console.log('slice product', product)
        dispatch(setProduct(product));
        dispatch(setVariant(product.variant)); //to correct variant attribute selection in product detail page

        // We need other related products from variant
        const getOtherProductsFromVariant = productTemplate.products.filter(
          (item) => item?.variant?.id === product?.variant?.id
        );

        const removeCurrentProduct = getOtherProductsFromVariant.filter(
          (item) => item?.id !== product?.id
        );

        dispatch(setOtherProductsFromVariant(removeCurrentProduct));
      }
      dispatch(setLoading(false));

      if (productTemplate?.productType === 'Medicine') {
        const loadedSubs = await productTemplateService.getProductTemplateSubs(
          productTemplate?.id
        );

        if (loadedSubs?.length > 0) {
          dispatch(
            setProductTemplate({ ...productTemplate, substitutes: loadedSubs })
          );
        }
      }
    }
    dispatch(setLoading(false));
  };

export const onProductVariantChange =
  (variant) => async (dispatch, getState) => {
    const { productTemplate } = getState().productTemplate;
    //console.log("productTemplate", productTemplate)
    dispatch(setVariant(variant)); //to correct variant attribute selection in product detail page


    if (productTemplate) {
      const selectedVariantBasedProduct = productTemplate.products.find(
        (item) => item?.variant?.id === variant.id
      );
      //console.log(selectedVariantBasedProduct, 'selectedVariantBasedProduct')
      if (selectedVariantBasedProduct) {
        //console.log(selectedVariantBasedProduct, 'selectedVariantBasedProduct');
        dispatch(setProduct(selectedVariantBasedProduct));

        // We need other related products from variant
        const getOtherProductsFromVariant = productTemplate.products.filter(
          (item) => item?.variant?.id === selectedVariantBasedProduct.variant.id
        );

        const removeCurrentProduct = getOtherProductsFromVariant.filter(
          (item) => item.id !== selectedVariantBasedProduct.id
        );

        dispatch(setOtherProductsFromVariant(removeCurrentProduct));
      } else {
        dispatch(setProduct(selectedVariantBasedProduct));
        dispatch(setOtherProductsFromVariant([]));
      }
    }
  };

// export const getProductTemplate = (id) => async (dispatch) => {
//   dispatch(setLoading(true))
//   const data = await productTemplateService.getProductTemplateById(id)

//   data.defaultProduct = []

//   if (data.products?.length > 0) {
//     data.products.forEach((item) => {
//       item.default && data.defaultProduct.push(item)
//     })
//   } else {
//     data.defaultProduct = data
//   }

//   if (data) {
//     dispatch(setProductTemplate(data))
//   }
//   dispatch(setLoading(false))
// }

export default productTemplateSlice.reducer;
