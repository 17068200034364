import instance from 'api'
const notificationService = {}

notificationService.getNotifications = async function () {
  try {
    const {
      data: { data },
    } = await instance.get('/api/v1/notifications?notificationType=Fcm')
    // const resData = data.filter((cur) => cur.status === 'Active')
    return data
  } catch (err) {
    //console.log(err, 'show-err')
  }
}

export default notificationService
